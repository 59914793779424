import React from "react";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import { TodayData } from "../../Types";
import { dateTrimmer, getTodayData, totalHours } from "../../utils/DateUtils";
import { User, LogIn, LogOut, Clock, Calendar } from 'lucide-react';
interface RecordModelInsyncProps {
  selectedRecord: TodayData | null;
  showModal: boolean;
  handleClose: () => void;
}

const RecordModelInsync: React.FC<RecordModelInsyncProps> = ({
  selectedRecord,
  showModal,
  handleClose,
}) => {
  if (!selectedRecord) return null;

  const todayData = getTodayData(selectedRecord);
  const workedHours = totalHours(selectedRecord).todayWorkedHours;

  return (
    <Modal show={showModal} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title className="text-primary font-semibold">Record Details</Modal.Title>
    </Modal.Header>
    <Modal.Body className="px-3">
      <ListGroup variant="flush" className="gap-1">
        <ListGroup.Item className="border-0 px-0">
          <Row className="align-items-center justify-content-between">
            <Col xs="auto" className="d-flex align-items-center gap-2">
              <User size={16} strokeWidth={2.5} />
              <strong className="font-semibold">Name:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {selectedRecord.employeeName}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <LogIn size={16} strokeWidth={2.5} />
              <strong className="font-semibold">Login Time:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {todayData.loginTime}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <LogOut size={16} strokeWidth={2.5} />
              <strong className="font-semibold">Logout Time:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {todayData.logoutTime}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <Clock size={16} strokeWidth={2.5} />
              <strong className="font-semibold">Total Time:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {workedHours}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <Calendar size={16} strokeWidth={2.5} />
              <strong className="font-semibold">Date:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {dateTrimmer(selectedRecord.createdAt)}
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default RecordModelInsync;

