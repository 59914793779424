import React from "react";
import { Deal } from "../../type/types";
import { MdBarChart } from "react-icons/md";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from "recharts";

interface GraphProps {
  filteredResults: Deal[];
}

const Graph: React.FC<GraphProps> = ({ filteredResults = [] }) => {
  const validFilteredResults = filteredResults.filter(
    (deal): deal is Deal & { dealTCV: number } =>
      typeof deal.dealTCV === "number"
  );

  return (
    <div
      className="shadow-lg w-full bg-white p-4 border-2 border-gray-300"
      style={{ borderRadius: "15px" }}
    >
      <h1 className="text-2xl ms-3 font-bold mb-2">
        <MdBarChart className="inline-block mb-1 text-gray-800" /> Deal Insights
      </h1>
      <p className="text-gray-600 ms-3 mb-4">
        This dashboard provides insights into various deal stages, types, and
        customer-wise distribution. The charts below represent the total
        contract value of deals in INR, categorized based on their attributes.
      </p>
      <hr className="ms-3 mr-5" />
      <div className="flex flex-col mb-4 space-y-6">
        {validFilteredResults.length > 0 ? (
          <>
            <div
              className="w-full bg-gray-100 p-4 mt-4 shadow"
              style={{ borderRadius: "15px" }}
            >
              <h2 className="text-lg font-bold mb-1">
                Deal by Stages
              </h2>
              <p className=" text-gray-600 mb-3">TCV in INR</p>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={validFilteredResults}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dealStage" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="dealTCV">
                    {validFilteredResults.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          (entry.dealTCV ?? 0) <= 5000 ? "#0088FE" : "#3cd070"
                        }
                      />
                    ))}
                  </Bar>
                  <Bar dataKey="firstName" fill="#82ca9d" name="FirstName" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div
              className="w-full bg-gray-100 p-4 mt-4 shadow"
              style={{ borderRadius: "15px" }}
            >
              <h2 className="text-lg font-bold mb-1">
                Types of Deals <br />
              </h2>
              <p className=" text-gray-600 mb-3">Fixed bid, T&M and Others</p>

              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={validFilteredResults}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dealType" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="dealTCV">
                    {validFilteredResults.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          (entry.dealTCV ?? 0) <= 5000 ? "#0088FE" : "#3cd070"
                        }
                      />
                    ))}
                  </Bar>
                  <Bar dataKey="dealType" fill="#82ca9d" name="Deal Type" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div
              className="w-full bg-gray-100 p-4 mt-4 shadow"
              style={{ borderRadius: "15px" }}
            >
              <h2 className="text-lg font-bold mb-1">
                Deals by Customer <br />
              </h2>
              <p className="text-gray-600 mb-3">
                  TCV identify key contributors
                </p>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={validFilteredResults}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dealCustomer" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="dealTCV">
                    {validFilteredResults.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          (entry.dealTCV ?? 0) <= 5000 ? "#0088FE" : "#3cd070"
                        }
                      />
                    ))}
                  </Bar>
                  <Bar
                    dataKey="dealCustomer"
                    fill="#82ca9d"
                    name="Deal Customer"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500 p-5 w-full">
            No data available for visualization.
          </div>
        )}
      </div>
    </div>
  );
};

export default Graph;
