import React, { useState, useMemo } from "react";
import { Badge, Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { totalHours } from "../../utils/DateUtils";
import settings from "../../config/Config";
import { dateTrimmer } from "../../utils/DateUtils";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import LoginTwoToneIcon from "@mui/icons-material/LoginTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import illustration from "../../assets/illustration.jpg";
import { getTimeFromDate } from "../../utils/DateUtils";
import { useUser } from "../../contexts/UserContext";
import { MyRecordInsyncProps } from "../../Types";
import RecordModelInsync from "../Header/RecordModelInsync";
import UserAvatar from "../../Helper/UserAvatar";
import { Record, TodayData } from "../../Types";
import { getBadgeVariant } from "../../Helper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotificationModel from "./NotificationModel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyRecordInsync: React.FC<MyRecordInsyncProps> = ({
  records,
  pageType,
}) => {
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const renderTooltip = (message: string) => (props: any) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    );


  const filteredRecords = useMemo(() => {
    if (!records || records.length === 0) {
      return [];
    }
    if (pageType === "MyRecord" && user) {
      return records.filter(
        (record: TodayData) => record.employeeEmail === user.email
      );
    }
    return records;
  }, [records, pageType, user]);

  if (!filteredRecords || filteredRecords.length === 0) {
    return (
      <div>
        <img
          src={illustration}
          className="justify-content-center align-items-center w-6/12 mx-auto"
        />
      </div>
    );
  }

  const handleViewDetails = (record: Record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const handleEditClick = (record: Record) => {
    setSelectedRecord(record);
    setShowNotificationModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <>
          <ToastContainer />

      {filteredRecords.map((record: Record) => {
        let { todayWorkedHours } = totalHours(record);

        const hours = parseInt(todayWorkedHours.split("h")[0], 10);

        todayWorkedHours =
          hours > settings?.constants?.workPerDay
            ? settings?.constants?.logoutWorkHours
            : todayWorkedHours;

        return (
          <Card
            key={record.id}
            className="my-3 p-3 mt-1"
            style={{
              fontSize: "16px",
              backgroundColor: "#fff",
              borderRadius: "12px",
              boxShadow: "0 6px 18px rgba(0, 0, 0, 0.05)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              fontFamily: "Inter, sans-serif",
              border: "none",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <UserAvatar name={record.employeeName} />
                <div style={{ minWidth: "150px" }}>
                  <div className="font-semibold">{record.employeeName}</div>
                </div>
              </div>

              <div>
                <Badge
                  className="d-flex align-items-center justify-content-center"
                  bg={getBadgeVariant(record.workType)}
                >
                  {record.workType === settings.constants.workOption[1] ? (
                    <HomeRoundedIcon
                      className="me-1"
                      style={{ height: "17px", width: "17px" }}
                    />
                  ) : (
                    <LocationOnRoundedIcon
                      className="me-1"
                      style={{ height: "17px", width: "17px" }}
                    />
                  )}
                  {record.workType === settings.constants.workOption[1]
                    ? "Home"
                    : "Office"}
                </Badge>
              </div>

              <div
                className="d-flex flex-column text-center position-relative"
                style={{ minWidth: "180px" }}
              >
                {record.id === user?.id && pageType === settings.constants.pageType[0] && (
                  <span
                    className="position-absolute top-0 end-0 me-[-10px] mt-2 cursor-pointer w-6 h-6 d-flex justify-content-center align-items-center"
                    style={{
                      zIndex: 10,
                      backgroundColor: "#333131",
                      borderRadius: "50%",
                      padding: "4px",
                    }}
                    onClick={() => handleEditClick(record)}
                  >
                    <EditOutlinedIcon
                      style={{ fontSize: "16px", color: "white" }}
                    />
                  </span>
                )}
                <span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300 justify-content-center align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Login")}
                  >
                    <span>
                      <LoginTwoToneIcon
                        className="me-1"
                        style={{ height: "20px", color: "green" }}
                      />
                      <span className="text-green-500 cursor-pointer">
                        {getTimeFromDate(record?.loginTime)}
                      </span>
                    </span>
                  </OverlayTrigger>{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Logout")}
                  >
                    <span>
                      <LogoutTwoToneIcon
                        className="me-1"
                        style={{ height: "20px", color: "red" }}
                      />
                      <span className="text-red-500 cursor-pointer">
                        {record.logoutTime === null
                          ? "00:00"
                          : getTimeFromDate(record.logoutTime)}
                      </span>
                    </span>
                  </OverlayTrigger>{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Total Time")}
                  >
                    <span>
                      <AccessTimeIcon
                        className="me-1"
                        style={{ height: "20px", color: "blue" }}
                      />
                      <span className="text-blue-500 cursor-pointer">
                        {todayWorkedHours}
                      </span>
                    </span>
                  </OverlayTrigger>{" "}
                </span>
              </div>
              {showNotificationModal && (

              <NotificationModel
                selectedRecord={selectedRecord}
                records={records}
                showModal={showNotificationModal}
                handleClose={() => setShowNotificationModal(false)}
              />
              )}
              <div style={{ minWidth: "100px" }}>
                <div>
                  <EventAvailableOutlinedIcon
                    className="me-1"
                    style={{ height: "20px" }}
                  />
                  {dateTrimmer(record.createdAt)}
                </div>
              </div>

              <div className="relative">
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("view Details")}
                >
                  <Button
                    style={{ background: "none", border: "none" }}
                    onClick={() => handleViewDetails(record)}
                  >
                    <FaEye style={{ color: "#3F4A70" }} />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </Card>
        );
      })}
      <RecordModelInsync
        selectedRecord={selectedRecord}
        showModal={showModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default MyRecordInsync;
