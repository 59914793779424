import settings from "../../config/Config";
import { TodayData, AnalyticsCardItem } from "../../Types";
import {get} from "lodash";
import { capitalizeFirstLetter } from "../../Helper/index";
import { CardComponentProps } from "../../Types";

export const AnalyticsCard = (todayData: TodayData): CardComponentProps[] => {
  const capitalizeText = (text: string): string => {
    return capitalizeFirstLetter(text);
  };

  return [
    {
      title: get(settings, "config.workLocationTitle", "Work Location"),
      content: capitalizeText(todayData.workType),
      img: <img src={"/SVG/Location.svg"} className="w-11"/>,
    },
    {
      title: get(settings, "config.loggedInTitle", "Logged In"),
      content: capitalizeText(todayData.loginTime),
      img: <img src={"/SVG/Time.svg"} className="w-11"/>,
    },
    {
      title: get(settings, "config.loggedOutTitle", "Logged Out"),
      content: capitalizeText(todayData.logoutTime),
      img: <img src={"/SVG/LogoutSVG.svg"} className="w-10" />,
    },
    {
      title: get(settings, "config.todayTitle", "Today"),
      content: capitalizeText(todayData.todayWorkedHours),
      img: <img src={"/SVG/CardSVG.svg"} className="w-10"/>,
    },
  ];
};
