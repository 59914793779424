import axios from "axios";
import moment from "moment";
import settings from "../config/config";
import { IUser, IErrors, IDateRange } from "../Types/pageTypes";
import { Dispatch, SetStateAction } from "react";
export const validateWfhLimits = async (
  selectedStartDate: string,
  selectedEndDate: string,
  user: IUser,
  setErrors: (update: (prevErrors: IErrors) => IErrors) => void
): Promise<boolean> => {
  try {

    if (!settings.config.API_URL || !settings.constants.wfhMessage) {
      throw new Error("Configuration data is missing");
    }
    
    const response = await axios.get<{ data: IDateRange[] }>(
      `${settings.config.API_URL}wfh-activity-log/${user.email}?status=Approved`
    );
    const wfhLogs = response.data.data;

    const selectedStart = moment(selectedStartDate);
    const selectedEnd = moment(selectedEndDate);

    if (selectedStart.isAfter(selectedEnd)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate: settings.constants.wfhMessage[0],
      }));
      return false;
    }

    let overlappingRequest = false;

    let totalWfhDaysInWeek = 0;
    let totalWfhDaysInMonth = 0;

    wfhLogs.forEach((log) => {
      const logStart = moment(log.start_date);
      const logEnd = moment(log.end_date);

      const logWeek = logStart.week();
      const logMonth = logStart.month();

      if (logWeek === selectedStart.week()) {
        totalWfhDaysInWeek += logEnd.diff(logStart, "days") + 1;
      }
      if (logMonth === selectedStart.month()) {
        totalWfhDaysInMonth += logEnd.diff(logStart, "days") + 1;
      }

      if (
        selectedStart.isSame(logStart, "day") ||
        selectedStart.isSame(logEnd, "day") ||
        selectedEnd.isSame(logStart, "day") ||
        selectedEnd.isSame(logEnd, "day") ||
        (selectedStart.isBefore(logStart, "day") &&
          selectedEnd.isAfter(logEnd, "day"))
      ) {
        overlappingRequest = true;
      }
    });
    if (overlappingRequest) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate:settings.constants.wfhMessage[1],
      }));
      return false;
    }

    const selectedDays = selectedEnd.diff(selectedStart, "days") + 1;

    if (totalWfhDaysInWeek + selectedDays > 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate:settings.constants.wfhMessage[2],
      }));
      return false;
    }

    if (totalWfhDaysInMonth + selectedDays > 5) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        startDate:settings.constants.wfhMessage[3],
      }));
      return false;
    }

    setErrors((prevErrors) => ({ ...prevErrors, startDate: "" }));
    return true;
  } catch (error) {
    console.error("Error fetching WFH logs", error);
    return false;
  }
};

export const validateDateDifference = (startDate: string, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const difference = end.diff(start, "days");
  return difference < 2;
};

export const wfhLimitsValid = async (
  selectedStartDate: string,
  selectedEndDate: string,
  user: IUser,
  setErrors: (update: (prevErrors: IErrors) => IErrors) => void
): Promise<boolean> => {
  return await validateWfhLimits(
    selectedStartDate,
    selectedEndDate,
    user,
    setErrors
  );
};



export const handleStartDateChange = async (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setStartDate: React.Dispatch<React.SetStateAction<string>>,
  endDate: string,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>,
  user: IUser
) => {
  const selectedStartDate = e.target.value;
  setStartDate(selectedStartDate);

  if (endDate && !validateDateDifference(selectedStartDate, endDate)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      endDate: "The difference between start and end date must not exceed 2 days",
    }));
  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      endDate: "",
    }));
  }

  await validateWfhLimits(selectedStartDate, endDate, user, setErrors);
};

export const handleEndDateChange = async (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setEndDate: React.Dispatch<React.SetStateAction<string>>,
  startDate: string,
  setErrors: React.Dispatch<React.SetStateAction<IErrors>>,
  user: IUser
) => {
  const selectedEndDate = e.target.value;
  setEndDate(selectedEndDate);

  if (startDate && !validateDateDifference(startDate, selectedEndDate)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      endDate: "The difference between start and end date must not exceed 2 days",
    }));
  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      endDate: "",
    }));
  }

  await validateWfhLimits(startDate, selectedEndDate, user, setErrors);
};