import React, { useState, useEffect } from 'react'; 
import { Dropdown } from 'react-bootstrap';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import settings from '../../config/config';
import InfoIcon from "@mui/icons-material/Info";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SvgIcon from '../SVG/svgIcons';
import { Buildings } from "react-bootstrap-icons";
import { get } from 'lodash';
import { filterProps } from '../../Types';

const CustomDropdown: React.FC<filterProps> = ({ options, onSelectStatus, radiobutton, reset }) => {
  const [selectedStatus, setSelectedStatus] = useState("All Statuses");

  const filterOptions = get(settings, 'constants.status', []);
  const workOptions = get(settings, 'constants.workOption', []);


  const getIcon = (iconCheck: string) => {
    switch (iconCheck.toLowerCase()) {
      case filterOptions[0].toLowerCase():
        return <CheckCircleOutlineIcon style={{ color: "black", width: "20px", height: "20px" }} className='mb-1' />;
      case filterOptions[1].toLowerCase():
        return < HighlightOffIcon style={{ color: "black", width: "20px", height: "20px" }} className='mb-1' />;
      case filterOptions[2].toLowerCase():
        return <CancelRoundedIcon style={{ color: "black", width: "20px", height: "20px" }} className='mb-1' />;
      case filterOptions[3].toLowerCase():
        return <InfoIcon  style={{ color: "black", width: "20px", height: "20px" }} className='mb-1' />;
      case workOptions[0]:
        return <Buildings style={{ width: "20px", height: "20px" }} className='mb-1' />;
      case workOptions[1]:
        return <SvgIcon iconType="remoteWork" width={20} height={20} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelectedStatus("All Statuses");
  }, [reset,radiobutton]);

  const handleSelect = (status : string) => {
    setSelectedStatus(status);
    onSelectStatus(status);
  };


  return (
    <Dropdown className='rounded-lg bg-white shadow-sm'>
      <Dropdown.Toggle
        id="dropdown-basic-button"
        variant="light"
        className='d-flex justify-content-between align-items-center'
        style={{ color: 'black', width: '240px', border: "1px solid #dee2e6", background: "#ffff" }}
      >
        <span className='d-flex align-items-center gap-1'>
          <FilterAltOutlinedIcon style={{ color: "green" }} />
          <PersonOutlineOutlinedIcon style={{ color: "blue" }} />
          <span>{(selectedStatus?.charAt(0).toUpperCase() + selectedStatus.slice(1))}</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: '200px' }}>
        {options.map((status, index) => (
          <Dropdown.Item key={index} onClick={() => handleSelect(status)}
            className="d-flex align-items-center"
          >
            <span style={{ marginRight: "8px" }}>
              {getIcon(status)}
            </span>
            {(status.charAt(0).toUpperCase() + status.slice(1))}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;

