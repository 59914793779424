import React, { createContext, useContext, useEffect, useState, ReactNode } from "react"; 
import { useUserDetails } from "../pages/Dashboard/UserDetails";
import settings from "../config/config";
import { IUser, UserContextProps } from "../Types/pageTypes";

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const userDetails = useUserDetails();
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [recentData, setRecentData] = useState<IUser | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (userDetails && userDetails.email) {
        try {
          const response = await fetch(
            `${settings.config.API_URL}employee/check-role?email=${userDetails.email}`
          );
          const data = await response.json();
          setRecentData(data);
        } catch (error) {
          console.error("Error fetching role data:", error);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && recentData) {
      setUser({
        name: userDetails.name,
        email: userDetails.email,
        managerEmail: userDetails.managerEmail,
        managerName: userDetails.managerName,
        roleId: recentData.roleId,
        showMenu: recentData.showMenu || false,
      });
    }
  }, [userDetails, recentData]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default UserContext;
