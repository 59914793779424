import Avatar from "@mui/material/Avatar";
import user_icon from "../assets/icon.png";
import _ from "lodash";

const index = ({ user }) => {
  const nameArray = _.get(user, "name", "").split(" ");
  const firstName = _.get(nameArray, "[0]", "");
  const lastName = _.get(nameArray, `[${nameArray.length - 1}]`, "");
  const shouldShowInitials = !_.isEmpty(firstName) && !_.isEmpty(lastName);

  return shouldShowInitials ? (
    <Avatar
      style={{
        backgroundColor: "black",
        color: "#ffff",
        width: 35,
        height: 35,
        fontSize: 15,
        fontWeight: 700,
        textAlign: "center",
      }}
    >
      {`${firstName[0]}${lastName[0]}`.toUpperCase()}
    </Avatar>
  ) : (
    <img src={user_icon} alt="user-icon" style={{ width: 35, height: 35 }} />
  );
};

export default index;
