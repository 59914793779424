import React from 'react';
import { Form } from 'react-bootstrap';
import { Calendar } from 'lucide-react';
import { CustomDateInputProps } from '../Types/Interface';

const CustomDateInput = React.forwardRef<HTMLInputElement, CustomDateInputProps>(
  ({ value, onClick, style }, ref) => (
    <div className="position-relative">
      <Form.Control
        ref={ref}
        value={value}
        onClick={onClick}
        readOnly
        style={{
          width: "210px",
          fontWeight: 600,
          paddingRight: "35px",
          ...style,
        }}
      />
      <Calendar
        size={18}
        className="position-absolute"
        style={{
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          color: "#6c757d",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    </div>
  )
);

export default CustomDateInput;