import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Container, Row, Col, Alert, Form } from "react-bootstrap";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import settings from "../config/Config";
import { MonthData } from "../Types/Interface";
import { UserContextProps } from "../Types/PageTypes";
 
const WfhBarGraph: React.FC = () => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [graphData, setGraphData] = useState<MonthData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser() as UserContextProps;
 
  const employeeEmail = user ? user.email : null;
 
  useEffect(() => {
    if (employeeEmail) {
      fetchData();
    }
  }, [year, employeeEmail]);
 
  const fetchData = async () => {
    try {
      const url = new URL(
        `wfh-activity-log/stats/${year}`,
        settings.config.API_URL
      );
      url.searchParams.append("email", employeeEmail || "");
 
      const response = await axios.get(url.toString());
      const apiData = response.data;
 
      const formattedData = apiData.map((monthData: any) => ({
        month: monthData.month,
        monthName: new Date(year, monthData.month - 1, 1).toLocaleString(
          "default",
          { month: "short" }
        ),
        balance: monthData.balance,
        consumed: monthData.consumed,
      }));
 
      setGraphData(formattedData);
      setError(null);
    } catch (err) {
      console.error("Error fetching WFH data:", err);
      setError(
        "Failed to fetch WFH data. Please check the console for more details."
      );
    }
  };
 
  const handleYearChange = (e: React.ChangeEvent<any>) => {
    const target = e.target as HTMLSelectElement;
    setYear(parseInt(target.value));
  };
 
  const calculateYAxisDomainAndTicks = () => {
    const maxDays = Math.max(...graphData.map((data) => data.consumed + data.balance));
    const roundedMax = maxDays % 2 === 0 ? maxDays + 1 : maxDays;
 
    const ticks = [];
    for (let i = 0; i <= roundedMax; i++) {
      if (i % 2 !== 0 || i === 0) {
        ticks.push(i);
      }
    }
 
    return { roundedMax, ticks, maxDays };
  };
 
 
 
  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }
 
  const { roundedMax, ticks } = calculateYAxisDomainAndTicks();
 
  return (
    <Container style={{ fontWeight: 600 }}>
      <Row
        className="position-relative align-items-center"
        style={{ paddingTop: "10px" }}
      >
        <Col xs={12} md={8} lg={9}>
          <div style={{ height: "350px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={graphData}>
                <XAxis dataKey="monthName" />
                <YAxis
                 domain={[0,roundedMax]}
                ticks={(() => {
                  return ticks;
                })()}
                label={{ value: "Days", angle: -90, position: "insideLeft" }}
              />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="balance"
                  name="Balance"
                  fill="#0d6efd"
                  barSize={15}
                />
                <Bar
                  dataKey="consumed"
                  name="Consumed"
                  fill="#dc3545"
                  barSize={15}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
 
        <Col
          xs={12}
          md={4}
          lg={3}
          className="position-absolute"
          style={{ top: "10px", right: "-100px" }}
        >
          <Form>
            <Form.Group>
              <Form.Label>Select Year</Form.Label>
              <Form.Control
                as="select"
                className="form-select"
                value={year}
                onChange={handleYearChange}
                style={{ fontWeight: 600, width: "100px" }}
              >
                {[2023, 2024, 2025].map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
 
export default WfhBarGraph;