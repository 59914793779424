import React, { useEffect, useState, createContext, useContext, ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/AuthConfig";
import { callMsGraph } from "../../config/Graph";
import { Spinner, Container } from "react-bootstrap";

interface UserDetailsContextType {
  name: string;
  email: string;
  managerEmail?: string;
  managerName?: string;
  profilePicture?: string;
}

interface UserDetailsProps {
  children: ReactNode;
}

export const UserDetailsContext = createContext<UserDetailsContextType | null>(null);

export const useUserDetails = (): UserDetailsContextType | null => useContext(UserDetailsContext);

const UserDetails: React.FC<UserDetailsProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [userDetails, setUserDetails] = useState<UserDetailsContextType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          const userData = await callMsGraph(response.accessToken);

          setUserDetails({
            name: userData.displayName,
            email: userData.mail || userData.userPrincipalName,
            managerEmail: userData.managerEmail,
            managerName: userData.managerName,
            profilePicture: userData.profilePicture,
          });
        } catch (error) {
          console.error("Error fetching user details:", error);
          instance.acquireTokenRedirect(loginRequest);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [instance, accounts]);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (error) {
    return <div>Error loading user details. Please try again.</div>;
  }

  return (
    <UserDetailsContext.Provider value={userDetails}>
      {children}
    </UserDetailsContext.Provider>
  );
};

export default UserDetails;
