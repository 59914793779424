import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import PaginateButton from "../PaginateButton/PaginateButton";
import React, { useEffect, useState } from "react";

interface PaginateProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
}

const Paginate: React.FC<PaginateProps> = ({ currentPage, totalPages, handlePageChange }) => {
  const [pageInput, setPageInput] = useState<number>(currentPage);

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(Number(e.target.value));
  };

  const handlePageJump = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const pageNumber = Number(pageInput);

      if (!Number.isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      } else {
        toast.error("Invalid page number", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col sm:flex-row sm:justify-between items-center space-y-2 sm:space-y-0 sm:space-x-2 mt-4 mb-4 me-4">
        <span className="text-gray-700 font-bold text-sm sm:text-base md:ms-16">
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-3 me-4">
          <PaginateButton
            onClick={() => handlePageChange(1)}
            icon={<ChevronsLeft size={18} />}
            disabled={currentPage === 1}
          />
          <PaginateButton
            onClick={() => handlePageChange(currentPage - 1)}
            icon={<ChevronLeft size={18} />}
            disabled={currentPage === 1}
          />
          <div className="flex items-center bg-gray-100 rounded-md shadow-sm border border-gray-300">
            <span className="text-gray-700 text-sm font-medium ms-3">
              Jump to
            </span>
            <Form.Control
              type="number"
              className="text-center text-gray-800 font-semibold bg-gray-100 border-none outline-none rounded-md ms-3"
              style={{ width: "60px", height: "32px" }}
              value={pageInput}
              onChange={handleInputChange}
              onKeyDown={handlePageJump}
            />
          </div>
          <PaginateButton
            onClick={() => handlePageChange(currentPage + 1)}
            icon={<ChevronRight size={18} />}
            disabled={currentPage >= totalPages}
          />
          <PaginateButton
            onClick={() => handlePageChange(totalPages)}
            icon={<ChevronsRight size={18} />}
            disabled={currentPage >= totalPages}
          />
        </div>
      </div>
    </>
  );
};

export default Paginate;
