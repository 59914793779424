import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { Spinner, Alert } from "react-bootstrap";
import settings from "../config/config";
 
const AttendanceRouter = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [navigating, setNavigating] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();
 
  useEffect(() => {
    const checkAttendance = async () => {
      if (!user || !user.email) {
        setError("User email is not available");
        setLoading(false);
        return;
      }
 
      const url = new URL(`employee-work-tracker/status/${user.email}`, settings.config.API_URL).toString();
 
try {
  const response = await fetch(url);
 
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
 
  const data = await response.json();
 
        setNavigating(true);
 
        if (data.hasLoggedInToday && !data.hasLoggedOutToday) {
          navigate("/logout", {
            state: { loginTime: data.loginTime, hasLoggedOutToday: false },
          });
        } else if (!data.hasLoggedInToday) {
          navigate("/checkin");
        } else if (data.hasLoggedInToday && data.hasLoggedOutToday) {
          navigate("/logout", {
            state: {
              loginTime: data.loginTime,
              logoutTime: data.logoutTime,
              hasLoggedOutToday: true,
            },
          });
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setError(`Failed to fetch attendance status: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
 
    checkAttendance();
  }, [user, navigate]);
 
  if (loading || navigating) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 h-screen">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
 
  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }
 
  return null;
};
 
export default AttendanceRouter;