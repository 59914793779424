import { FaUserPlus } from "react-icons/fa";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import React, { useState } from "react";
import validationSchema from "../../schema/schema";

interface DealFormValues {
  dealNo: string;
  firstName: string;
  LastName: string;
  dealSource: string;
  dealPartner: string;
  dealCustomer: string;
  dealstatus: string;
  dealStage: string;
  opportunityReceivedDate: string;
  expectedClosureDate: string;
  dealTitle: string;
  winProbability: string;
  dealTCV: string;
  dealCurrency: string;
  dealType: string;
  dealDurationMonths: string;
  lastUpdatedOn: string;
  lastUpdateRemarks: string;
}

const AddDealForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<DealFormValues>({
    initialValues: {
      dealNo: "",
      firstName: "",
      LastName: "",
      dealSource: "",
      dealPartner: "",
      dealCustomer: "",
      dealstatus: "",
      dealStage: "",
      opportunityReceivedDate: "",
      expectedClosureDate: "",
      dealTitle: "",
      winProbability: "",
      dealTCV: "",
      dealCurrency: "",
      dealType: "",
      dealDurationMonths: "",
      lastUpdatedOn: "",
      lastUpdateRemarks: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        console.log("Submitting:", values);
        const response = await axios.post("/api/create", values);
        console.log("API Response:", response);
        setTimeout(() => {
          setLoading(false);
          toast.success("Successfully Created a New Deal!", {
            position: "top-right",
            autoClose: 3000,
          });
        }, 5000);
          setTimeout(() => navigate("/dealmanagement"), 8000);
       
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast.error("Failed to create deal. Try Again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <div className="container-fluid">
      <ToastContainer />
      <Form
        onSubmit={formik.handleSubmit}
        className="p-3 mt-3"
        autoComplete="off"
      >
        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="dealNo" className="mb-3 relative">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Deal No*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal No"
                {...formik.getFieldProps("dealNo")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealNo && formik.errors.dealNo && (
                <div className="text-red-500 text-sm mt-2 font-medium">
                  {formik.errors.dealNo}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="firstName" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                First Name*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Full Name"
                {...formik.getFieldProps("firstName")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.firstName}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="LastName" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Last Name*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                {...formik.getFieldProps("LastName")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.LastName && formik.errors.LastName && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.LastName}*
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="dealSource" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Source*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal Source"
                {...formik.getFieldProps("dealSource")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealSource && formik.errors.dealSource && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealSource}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealPartner" className="mb-3">
              <Form.Label className=" block text-gray-600 text-sm font-medium ">
                Partner*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal Partner"
                {...formik.getFieldProps("dealPartner")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealPartner && formik.errors.dealPartner && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealPartner}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealCustomer" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Customer*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal Customer"
                {...formik.getFieldProps("dealCustomer")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealCustomer && formik.errors.dealCustomer && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealCustomer}*
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="dealstatus" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Status*
              </Form.Label>
              <Form.Select
                name="dealstatus"
                {...formik.getFieldProps("dealstatus")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Status</option>
                <option value="In-progress">In-progress</option>
                <option value="Active">Active</option>
              </Form.Select>
              {formik.touched.dealstatus && formik.errors.dealstatus && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealstatus}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealStage" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Stage*
              </Form.Label>
              <Form.Select
                name="dealStage"
                {...formik.getFieldProps("dealStage")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Stage</option>
                <option value="L0">L0</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L3">L3</option>
              </Form.Select>
              {formik.touched.dealStage && formik.errors.dealStage && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealStage}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="opportunityReceivedDate" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Opportunity Received Date*
              </Form.Label>
              <Form.Control
                type="date"
                {...formik.getFieldProps("opportunityReceivedDate")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.opportunityReceivedDate &&
                formik.errors.opportunityReceivedDate && (
                  <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                    {formik.errors.opportunityReceivedDate}*
                  </div>
                )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="expectedClosureDate" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Expected Closure Date*
              </Form.Label>
              <Form.Control
                type="date"
                {...formik.getFieldProps("expectedClosureDate")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.expectedClosureDate &&
                formik.errors.expectedClosureDate && (
                  <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                    {formik.errors.expectedClosureDate}*
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealTitle" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Title*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal Title"
                {...formik.getFieldProps("dealTitle")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealTitle && formik.errors.dealTitle && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealTitle}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="winProbability" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Win Probability*
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Win Probability"
                {...formik.getFieldProps("winProbability")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.winProbability &&
                formik.errors.winProbability && (
                  <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                    {formik.errors.winProbability}*
                  </div>
                )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="dealTCV" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Total Contract Value*
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Deal TCV"
                {...formik.getFieldProps("dealTCV")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealTCV && formik.errors.dealTCV && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealTCV}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealCurrency" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Currency*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Deal Currency"
                {...formik.getFieldProps("dealCurrency")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealCurrency && formik.errors.dealCurrency && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealCurrency}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="dealType" className="mb-3">
              <Form.Label className=" block text-gray-600 text-sm font-medium">
                Type*
              </Form.Label>
              <Form.Control
                as="select"
                {...formik.getFieldProps("dealType")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Type</option>
                <option value="FB">FB</option>
                <option value="T&M">T&M</option>
                <option value="BOH">BOH</option>
              </Form.Control>
              {formik.touched.dealType && formik.errors.dealTCV && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.dealTCV}*
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group controlId="dealDurationMonths" className="mb-3">
              <Form.Label className=" block text-gray-600 text-sm font-medium">
                Duration Months*
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Deal Duration Months"
                {...formik.getFieldProps("dealDurationMonths")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealDurationMonths &&
                formik.errors.dealDurationMonths && (
                  <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                    {formik.errors.dealDurationMonths}*
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group controlId="lastUpdatedOn" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Last Updated On*
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Last Updated On"
                {...formik.getFieldProps("lastUpdatedOn")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.lastUpdatedOn && formik.errors.lastUpdatedOn && (
                <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                  {formik.errors.lastUpdatedOn}*
                </div>
              )}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="lastUpdateRemarks" className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Update Remarks*
              </Form.Label>
              <Form.Control
                as="textarea"
                name="lastUpdateRemarks"
                placeholder="Last Updated Remarks"
                {...formik.getFieldProps("lastUpdateRemarks")}
                onChange={formik.handleChange}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
                rows={5}
              />
              {formik.touched.lastUpdateRemarks &&
                formik.errors.lastUpdateRemarks && (
                  <div className="text-red-500 text-sm ms-2 mt-2 font-medium">
                    {formik.errors.lastUpdateRemarks}*
                  </div>
                )}
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center flex justify-center">
          <Button
            variant="primary"
            type="submit"
            className="w-40 h-12 !font-semibold mt-3 "
            disabled = {loading}
          >
            {loading ? (
              <>
              <Spinner animation="border" size="sm"/>
              </>
            ) : (
              <>
               <FaUserPlus className="inline-block mb-1 mr-1" />{" "}
               <span className="text-center">Create Deal</span>
              </>
            )}
           
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddDealForm;
