import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import settings from "../config/Config";
import { IUser, IDateRange, UseFetchBalanceResult } from "../Types/PageTypes";
 
 
export const useFetchBalance = (user: IUser | null ): UseFetchBalanceResult => {
  const [balance, setBalance] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
 
 
  useEffect(() => {
    setLoading(true);
    const fetchBalance = async () => {
      if (!user) return;
      const limitResponse = await axios.get<{ email: string; wfh_limit: number }>(
        `${settings.config.API_URL.replace(/\/$/, "")}/${settings.config.WFH_LIMIT_API_URL.replace(/^\//, "")}?email=${user.email}`
      );
      const wfhLimit = limitResponse.data.wfh_limit;
     
      try {
        const response = await axios.get<{data:IDateRange[]}>(
          `${settings.config.API_URL}wfh-activity-log/${user.email}?status=Approved`
        );
        const wfhLogs = response.data.data;
        setLoading(false);
 
        const currentMonth = moment().month();
        const currentYear = moment().year();
 
        let totalDays = wfhLogs.reduce((acc, log) => {
          const logStartDate =moment(log.start_date);
          const logEndDate =moment(log.end_date);
 
          const logMonth = logStartDate.month();
          const logYear = logStartDate.year();
 
          if (logMonth === currentMonth && logYear === currentYear) {
            const logDays = logEndDate.diff(logStartDate, 'days') + 1;
            return acc + logDays;
          }
          return acc;
        }, 0);
 
 
        totalDays = wfhLimit - totalDays;
        setBalance(totalDays < 0 ? 0 : totalDays);
      } catch (error) {
        console.error("Error fetching WFH logs", error);
        setError(error);
        setLoading(false);
      }
    };
 
    fetchBalance();
  }, [user]);
 
  return { balance, error,loading };
};