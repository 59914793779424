import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import settings from "../../config/config";
import { AllRecord, DownloadProps } from "../../Types";

const Download : React.FC<DownloadProps> = ({ wfhRecords = [], insyncRecords = [], radiobutton }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const excludeFieldsWFH = settings.constants.FieldsWFH;
  const excludeFieldsWorkTracker = settings.constants.FieldsWorkTracker;

  const filterFields = (data : AllRecord[], excludeFields : string[]) => {
    return data.map((record) => {
      const filteredRecord = {};
      Object.keys(record).forEach((key) => {
        if (!excludeFields.includes(key)) {
          filteredRecord[key] = record[key];
        }
      });
      return filteredRecord;
    });
  };

  const convertToCSV = (data : AllRecord[]) : string => {
    if (!data || data.length === 0) {
      return "";
    }
    const headers = Object.keys(data[0]);
    const csvRows = data.map((row) => headers.map((field) => `"${row[field]}"`).join(","));
    return [headers.join(","), ...csvRows].join("\n");
  };

  const handleDownload = () => {
    let csvData = "";
    let filteredRecords = [];
    let fileName = settings?.constants?.fileNames[3]; 

    switch (radiobutton) {
      case "1":
        if (wfhRecords.length) {
          filteredRecords = filterFields(wfhRecords, excludeFieldsWFH);
          csvData = `${convertToCSV(filteredRecords)}`;
          fileName = settings?.constants?.fileNames[0];
        } else {
          csvData = settings.constants.fileNames[2];
        }
        break;

      case "2":
        if (insyncRecords.length) {
          filteredRecords = filterFields(insyncRecords, excludeFieldsWorkTracker);
          csvData = `${convertToCSV(filteredRecords)}`;
          fileName = settings?.constants?.fileNames[1];
        } else {
          csvData = settings?.constants?.fileNames[2];
        }
        break;

      default:
        csvData = settings?.constants?.fileNames[2];
        break;
    }

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const alldocumnet = document.createElement("a");
    alldocumnet.href = url;
    alldocumnet.download = fileName; 
    alldocumnet.click();
    window.URL.revokeObjectURL(url);
  };

  const isDisabled = (radiobutton === "1" && wfhRecords.length === 0) || 
                     (radiobutton === "2" && insyncRecords.length === 0);

  return (
    <Button
      className="shadow-sm"
      onClick={handleDownload}
      disabled={isDisabled}
      style={{
        backgroundColor: "#0d6dfc",
        color: "black",
        border: "none",
        fontSize: isSmallMobile ? "10px" : isTabletOrMobile ? "12px" : "15px",
        width: isSmallMobile ? "70px":"110px",
        height: isSmallMobile ? "32px" : "39px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: "5px",
      }}
    >
      <span
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ color: "#ffff" }}
      >
        <FaDownload />
        Export
      </span>
    </Button>
  );
};

export default Download;
