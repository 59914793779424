import React, { ReactNode } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
  icon?: ReactNode;
  description?: string;
  showBackButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  icon,
  description,
  showBackButton = true,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center space-x-2">
        {showBackButton && (
          <FiArrowLeft
            className="text-gray-500 text-2xl cursor-pointer hover:text-gray-800"
            onClick={() => navigate(-1)}
          />
        )}
        {icon && <span className="text-gray-700 text-2xl">{icon}</span>}
        <h2 className="text-2xl font-bold text-gray-800">{title}</h2>
      </div>
      {description && <p className="text-gray-600 text-base ms-2">{description}</p>}
    </div>
  );
};

export default Header;
