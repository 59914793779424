import { FaSearch, FaDatabase, FaUserPlus } from "react-icons/fa";
import { Form, Button, Spinner } from "react-bootstrap";
import React, { ChangeEvent, useState } from "react";

interface SearchFilterProps {
  queryField: string;
  searchString: string;
  handleQueryFieldChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleSearchStringChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;  
  fetchResults: () => void;
  handleDealForm: () => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  queryField,
  searchString,
  handleQueryFieldChange,
  handleSearchStringChange,
  fetchResults,
  handleDealForm,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleQueryClick = async () => {
    setIsLoading(true); 
    
    setTimeout(async () => {
      await fetchResults(); 
      setIsLoading(false); 
    }, 5000);
  };
  return (
    <Form autoComplete="off" className="mt-0 pt-3 pb-3">
      <div className="flex flex-wrap gap-3 items-center justify-between">
        <Form.Group controlId="queryField" className="relative flex-grow">
          <Form.Select
            value={queryField}
            aria-label="Default select example"
            onChange={handleQueryFieldChange}
            className="border rounded-md p-2 w-full h-12 focus:ring-2 focus:ring-blue-500 px-4"
          >
            <option value="">Select Field</option>
            <option value="all">All</option>
            <option value="dealNo">Deal No</option>
            <option value="owner">Owner</option>
            <option value="dealstatus">Deal Status</option>
            <option value="dealStage">Deal Stage</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="searchString" className="relative flex-grow">
          {queryField === "dealstatus" ? (
            <Form.Select
              value={searchString}
              onChange={handleSearchStringChange}
              className="border rounded-md p-2 w-full h-12 focus:ring-2 focus:ring-blue-500 px-4"
            >
              <option value="">Select Deal Status</option>
              <option value="In-progress">In-progress</option>
              <option value="Active">Active</option>
            </Form.Select>
          ) : queryField === "dealStage" ? (
            <Form.Select
              value={searchString}
              onChange={handleSearchStringChange}
              className="border rounded-md p-2 w-full h-12 focus:ring-2 focus:ring-blue-500 px-4"
            >
              <option value="">Select Deal Stage</option>
              <option value="L0">L0</option>
              <option value="L1">L1</option>
              <option value="L2">L2</option>
              <option value="L3">L3</option>
            </Form.Select>
          ) : (
            <div className="relative">
      <Form.Control
        type="text"
        value={searchString}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleSearchStringChange(event)
        }
        placeholder={
          queryField === "owner" ? "Enter Owner Name..." : "Enter the text..."
        }
        className="border rounded-md p-2 w-full h-12 focus:ring-2 focus:ring-blue-500 px-4"
      />
      <FaSearch className="absolute right-8 top-4 text-gray-500" />
    </div>
  )}
        </Form.Group>

        <Button
          variant="success"
          onClick={handleQueryClick}
          className="px-4 h-12 !font-semibold whitespace-nowrap"
          disabled={isLoading}
        >
           {isLoading ? (
            <>
              <Spinner animation="border" size="sm"/>
            </>
          ) : (
            <>
              <FaDatabase className="inline-block mr-2" /> Query
            </>
          )}
        </Button>

        <Button
          variant="primary"
          onClick={handleDealForm}
          className="px-4 h-12 !font-semibold flex items-center whitespace-nowrap"
        >
          <FaUserPlus className="inline-block mb-1 mr-2" /> Add Deal Form
        </Button>
      </div>
    </Form>
  );
};

export default SearchFilter;