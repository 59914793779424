import React, { useState, useEffect } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'; 
import { searchProps } from '../../Types';

const Search: React.FC<searchProps> = ({ onSearch,resetSearchTrigger,reset }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
        handleSearch();
    }, 1000); 

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);


  useEffect(() => {
    setSearchTerm('');
  }, [reset,resetSearchTrigger]);


  return (
    <div className="flex items-center border rounded-lg bg-white shadow-sm">
      <SearchOutlinedIcon className="ml-2 mt-1" />
      <input
        type="text"
        placeholder="Search..."
        className="w-full p-2 focus:outline-none h-9 rounded-lg bg-white-100 placeholder-black"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        }}
      />
    </div>
  );
};

export default Search;
