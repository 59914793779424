import { Card, Col } from "react-bootstrap";
import {
  FaExclamationTriangle,
  FaClock,
  FaCalendarAlt,
  FaGift,
} from "react-icons/fa";

const index = () => {
    

  return (
    <Col md={12}>
      <Card
        style={{
          backgroundColor: "#FFF9C4",
          borderRadius: "10px",
          padding: "15px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          position: "relative",
          overflow: "hidden",
          margin: "20px auto",
          width: "96%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "5px",
            height: "100%",
            background: "#fa9a41",
          }}
        />

        <Card.Body>
          <h5
            className="d-flex"
            style={{
              color: "#A0522D",
              fontWeight: "bold",
            }}
          >
            <FaExclamationTriangle
              style={{
                marginRight: "10px",
                height: "18px",
                width: "18px",
              }}
            />
            Important Notes:
          </h5>
          <ul
            style={{
              listStyle: "none",
              paddingLeft: 0,
              marginTop: "10px",
            }}
          >
            <li
              className="d-flex align items-center"
              style={{ marginTop: "10px" }}
            >
              <FaClock style={{ color: "#007BFF", marginRight: "10px" }} />
              Requests should not exceed 2 days a week.
            </li>
            <li
              className="d-flex align items-center"
              style={{ marginTop: "10px" }}
            >
              <FaCalendarAlt
                style={{ color: "#28A745", marginRight: "10px" }}
              />
              Requests limited to 5 days per month.
            </li>
            <li
              className="d-flex align items-center"
              style={{ marginTop: "10px" }}
            >
              <FaGift style={{ color: "#FF69B4", marginRight: "10px" }} />
              All requests will be sent to your Reporting manager.
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default index;
