import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Record } from "../../Types";
import { dateTrimmer } from "../../Helper/DateUtils";

interface RecordModalProps {
  selectedRecord: Record | null;
  showModal: boolean;
  handleClose: () => void;
}

const RecordModal: React.FC<RecordModalProps> = ({
  selectedRecord,
  showModal,
  handleClose,
}) => {
  if (!selectedRecord) return null;

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Record Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong> {selectedRecord.first_name} {selectedRecord.last_name}
        </p>
        <p>
          <strong>Status:</strong> {selectedRecord.status.charAt(0).toUpperCase() +
            selectedRecord.status.slice(1)}
        </p>
        <p>
          <strong>Date Range:</strong> {dateTrimmer(selectedRecord.start_date)} to{" "}
          {dateTrimmer(selectedRecord.end_date)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RecordModal;
