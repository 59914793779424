import { Location, OfficeLocations, LocationCheckResult } from '../Types/Interface';
import settings from '../config/Config';

const MAX_OFFICE_DISTANCE_KM = 3;
const EARTH_RADIUS_KM = 6371;

export const calculateDistance = (
  latitude1: number,
  longitude1: number,
  latitude2: number,
  longitude2: number
): number => {
  const deltaLatitude = ((latitude2 - latitude1) * Math.PI) / 180;
  const deltaLongitude = ((longitude2 - longitude1) * Math.PI) / 180;
  
  const area =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos((latitude1 * Math.PI) / 180) *
    Math.cos((latitude2 * Math.PI) / 180) *
    Math.sin(deltaLongitude / 2) *
    Math.sin(deltaLongitude / 2);
    
  const centralAngle = 2 * Math.atan2(Math.sqrt(area), Math.sqrt(1 - area));
  return EARTH_RADIUS_KM * centralAngle;
};

export const checkOfficeProximity = (
  userLat: number,
  userLon: number
): LocationCheckResult => {
  let nearest: string | null = null;
  let minDistance = Infinity;
  let isNearOffice = false;

  for (const [city, coords] of Object.entries(settings.officeLocations as OfficeLocations)) {
    const distance = calculateDistance(userLat, userLon, coords.lat, coords.lon);

    if (distance < minDistance) {
      minDistance = distance;
      nearest = city;
    }

    if (distance <= MAX_OFFICE_DISTANCE_KM) {
      isNearOffice = true;
      nearest = city;
      break;
    }
  }

  return {
    isNearOffice,
    nearestOffice: nearest,
    userLocation: { lat: userLat, lon: userLon }
  };
};

export const getUserLocation = async (): Promise<LocationCheckResult> => {
  if (!navigator.geolocation) {
    throw new Error("Geolocation is not supported by this browser.");
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const proximityResult = checkOfficeProximity(latitude, longitude);
        resolve(proximityResult);
      },
      (error) => {
        reject(new Error(`Failed to get location: ${error.message}`));
      },
      { 
        enableHighAccuracy: true, 
        timeout: 10000, 
        maximumAge: 0 
      }
    );
  });
};