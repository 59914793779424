import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { Spinner } from "react-bootstrap";

interface ProtectedRouteProps {
  requiresManager?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiresManager }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user !== undefined && user !== null) { 
      if (!user.showMenu) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  if (user === undefined || user === null) {
    return <Spinner/>; 
  }

  return user.showMenu ? <Outlet /> : null;
};

export default ProtectedRoute;
