import settings from "../../config/config";
import SvgIcon from "../SVG/svgIcons";
import { TodayData, AnalyticsCardItem } from "../../Types";
import {get} from "lodash";
import { capitalizeFirstLetter } from "../../Helper/index";

export const AnalyticsCard = (todayData: TodayData): AnalyticsCardItem[] => {
  const capitalizeText = (text: string): string => {
    return capitalizeFirstLetter(text);
  };

  return [
    {
      title: get(settings, "config.workLocationTitle", "Work Location"),
      content: capitalizeText(todayData.workType),
      img: <SvgIcon iconType="location" width={45} height={45} />,
    },
    {
      title: get(settings, "config.loggedInTitle", "Logged In"),
      content: capitalizeText(todayData.loginTime),
      img: <SvgIcon iconType="time" width={43} height={43} />,
    },
    {
      title: get(settings, "config.loggedOutTitle", "Logged Out"),
      content: capitalizeText(todayData.logoutTime),
      img: <SvgIcon iconType="login" width={40} height={40} />,
    },
    {
      title: get(settings, "config.todayTitle", "Today"),
      content: capitalizeText(todayData.todayWorkedHours),
      img: <SvgIcon iconType="logout" width={43} height={43} />,
    },
  ];
};
