import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoutIcon from '@mui/icons-material/Logout';

export const handleLogout = (instance: any) => {
  instance.logoutRedirect().catch((e: Error) => {
    console.error("Logout failed", e);
  });
};

const Logoutmsal: React.FC = () => {
  const { instance } = useMsal();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleShowConfirmation = () => setShowConfirmation(true);
  const handleCloseConfirmation = () => setShowConfirmation(false);


  return (
    <>
      <Button
        onClick={handleShowConfirmation}
        style={{
          color: 'white',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '132%',
          transition: 'background-color 0.3s, transform 0.3s',
          marginLeft: '-4px',
          textAlign: 'left',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: 20
        }}
        onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.currentTarget.style.backgroundColor = '#3F4A70';
        }}
        onMouseOut={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.currentTarget.style.backgroundColor = 'transparent';
          e.currentTarget.style.transform = 'scale(1)';
        }}
      >
        <div
          style={{
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '1rem',
            marginLeft: '0.5rem',
            fontSize: 20
          }}
        >
          <LogoutIcon style={{ fontSize: '19px', marginLeft: '2px' }} />
        </div>
        <span
          style={{
            fontSize: "17px",
            fontFamily: "Inter, sans-serif",
            fontWeight: 500,
            marginLeft: "-0.2rem",
          }}
        >
          Logout
        </span>
      </Button>

      <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout from Codintools?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCloseConfirmation();
              handleLogout(instance);  
            }}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logoutmsal;
