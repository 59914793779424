 
import { TodayData } from "../Types";

export const dateTrimmer = (todayDate ? : string) => { 
  if (!todayDate) return "N/A"; 
  return todayDate.substring(0, 10);
};

export const getTimeFromDate = (dateString : string) => {
  const date = new Date(dateString);
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};



export const getTodayData = (userRecords :TodayData) : TodayData => {
  try {
    const loginTimeUTC = userRecords?.loginTime || null;
    const logoutTimeUTC = userRecords?.logoutTime || null;

    if (loginTimeUTC) {
      const loginTime = new Date(loginTimeUTC);
      const logoutTime = logoutTimeUTC ? new Date(logoutTimeUTC) : new Date();

      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999);

      if (loginTime >= startOfDay && loginTime <= endOfDay) {
        const workedMilliseconds = logoutTime.getTime() - loginTime.getTime();
        const workedHours = Math.floor(workedMilliseconds / (1000 * 60 * 60));
        const workedMinutes = Math.floor(
          (workedMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );

        return {
          loginTime: loginTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          logoutTime: logoutTimeUTC
            ? logoutTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : "--",
          todayWorkedHours: `${workedHours}h ${workedMinutes}m`,
          workType: userRecords?.workType || process.env.REACT_APP_WORKTYPE_DEFAULT,
        };
      }
    }
  } catch (error) {
    console.error("Error processing today's data:", error);
  }

  return {
    loginTime: process.env.REACT_APP_LOGIN_DEFAULT,
    todayWorkedHours: process.env.REACT_APP_WORK_HOUR_DEFAULT,
    logoutTime: process.env.REACT_APP_LOGOUT_DEFAULT,
    workType: process.env.REACT_APP_WORKTYPE_DEFAULT,
  };
};


export const totalHours = (record : TodayData) : TodayData => {

  const loginTime = record.loginTime ? new Date(record.loginTime) : null;

  const logoutTime = record.logoutTime ? new Date(record.logoutTime) : new Date(); 

  if (!loginTime) {
    return {
      workType: record.workType || process.env.REACT_APP_WORKTYPE_DEFAULT || "Default Work Type",
      loginTime: process.env.REACT_APP_LOGIN_DEFAULT || "--",
      logoutTime: process.env.REACT_APP_LOGOUT_DEFAULT || "--",
      todayWorkedHours: "00h00m",
    };
  }

  const diffInMs = logoutTime.getTime() - loginTime.getTime();

  const workedHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const workedMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  return {
    workType: record.workType || process.env.REACT_APP_WORKTYPE_DEFAULT || "Default Work Type",
    loginTime: record.loginTime,
    logoutTime: record.logoutTime || "--",
    todayWorkedHours: `${workedHours}h ${workedMinutes}m`,
  };
};
