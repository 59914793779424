import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useUserDetails } from "../pages/Dashboard/UserDetails";
import settings from "../config/Config";
import { IUser, UserContextProps } from "../Types/PageTypes";
import { TodayData } from "../Types";
import axios from "axios";

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const userDetails = useUserDetails();
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [recentData, setRecentData] = useState<IUser | null>(null);
  const [WorkData, setWorkData] = useState<TodayData | null>(null);
  const [workError, setWorkError] = useState<string | null>(null);
  const [notificationId, setNotificationId] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (userDetails?.email) {
        try {
          const response = await fetch(
            `${settings.config.API_URL}employee/check-role?email=${userDetails.email}`
          );
          const data = await response.json();
          setRecentData(data);
        } catch (error) {
          console.error("Error fetching role data:", error);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [userDetails]);

  useEffect(() => {
    const fetchDataForNotification = async () => {
      if (userDetails?.email) {
        try {
          const response = await axios.get(
            `${settings.config.API_URL}employee-work-tracker/update-checkout-status?email=${userDetails.email}`
          );
          if (response.data?.record) {
            setWorkData(response.data.record);
            setNotificationId(response.data.record.id);
          }
        } catch (error) {
          console.error("Error fetching notification data:", error);
          setWorkError("Error fetching notification data");
        }
      }
    };

    fetchDataForNotification();
  }, [userDetails]);

  
  useEffect(() => {
    if (userDetails && recentData) {
      setUser({
        name: userDetails.name,
        email: userDetails.email,
        managerEmail: userDetails.managerEmail,
        managerName: userDetails.managerName,
        roleId: recentData.roleId,
        showMenu: recentData.showMenu || false,
        profilePicture: userDetails.profilePicture,
        id: notificationId ?? "",
        record: WorkData,
      });
    }
  }, [userDetails, recentData, WorkData, notificationId]);

  const logout = () => {
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default UserContext;
