import { useRef, useState } from "react";
import Webcam from "react-webcam";
import { toast } from "react-toastify";

export const initializeCamera = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, setCameraError: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setLoading(false);
    }
  } catch (err) {
    setLoading(false);
    setCameraError(true);
    toast.error("Please allow camera access to use this feature.");
  }
};

export const capturePhoto = (
  webcamRef: React.RefObject<Webcam>,
  setPhoto: React.Dispatch<React.SetStateAction<string | null>>,
  setFormData: React.Dispatch<React.SetStateAction<any>>
): void => {
  if (webcamRef.current) {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
    setFormData((prevData) => ({
      ...prevData,
      photo: imageSrc,
    }));
  } else {
    console.error("Webcam is not accessible");
  }
};

export const handleRetakePhoto = (
  setPhoto: React.Dispatch<React.SetStateAction<string | null>>,
  setFormData: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  setPhoto(null);
  setFormData((prevData) => ({
    ...prevData,
    photo: null,
  }));
  setLoading(true);
  setTimeout(() => setLoading(false), 1000);
};
