export const callMsGraph = async (accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const userResponse = await fetch("https://graph.microsoft.com/v1.0/me", options);
    const userData = await userResponse.json();

    const managerResponse = await fetch("https://graph.microsoft.com/v1.0/me/manager", options);
    const managerData = await managerResponse.json();

    return {
      ...userData,
      managerEmail: managerData.mail,
      managerName: managerData.displayName, 
    };
  } catch (error) {
    return null;
  }
};
