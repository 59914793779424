import { Container, Card } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import EditForm from "../components/EditForm/EditForm";
import Header from "../components/Header/Header";
import React from "react";

const EditDealForm: React.FC = () => {
  return (
    <div className="w-full p-4 min-h-screen flex flex-col mt-5">
      <Container className="pt-4 mx-auto w-full lg:ps-44">
        <div className="mb-6 ps-4 md:ps-8 lg:ps-16">
          <h2 className="text-2xl font-bold text-gray-800 flex items-center gap-2 mb-2 lg:text-3xl">
            <MdEdit className="text-gray-700" /> Edit Deal Management
          </h2>
          <p className="text-gray-600 text-sm md:text-base lg:text-lg">
            Modify existing deal details. Ensure accuracy before submitting your
            changes.
          </p>
        </div>
        <Card
          className="shadow-lg w-full p-3 border-2 border-gray-300"
          style={{ borderRadius: "15px", width:"107%" }}
        >
          <Card.Body>
            <div className="ms-2 mb-3 md:ms-10 lg:ms-16">
              <Header
                title="Edit Deal Form"
                description="Use this form to edit and update deal details. Ensure all required
              fields are filled correctly before submitting."
              />
            </div>
            <hr className="text-gray-900 ms-2 mr-4" />
            <EditForm />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default EditDealForm;
