import { WorkTrackerRecord, ApiErrorResponse } from '../Types/Interface';
import settings from '../config/Config';

export const calculateWorkingDuration = (loginTime: Date): string => {
  const now = new Date();
  const durationInSeconds = Math.floor(
    (now.getTime() - loginTime.getTime()) / 1000
  );
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
};

interface FetchTodayRecordResult {
  loginTime: Date | null;
  logoutTime: Date | null;
  isLoggedOut: boolean;
  error?: string;
}

export const fetchTodayRecord = async (
  userEmail: string | undefined
): Promise<FetchTodayRecordResult> => {
  try {
    if (!userEmail) {
      throw new Error('User email is required');
    }

    const url = new URL(
      `employee-work-tracker/today-record/${userEmail}`,
      settings.config.API_URL
    ).toString();
    
    const response = await fetch(url);
    
    if (response.ok) {
      const data = await response.json() as WorkTrackerRecord;
      return {
        loginTime: data.loginTime ? new Date(data.loginTime) : null,
        logoutTime: data.logoutTime ? new Date(data.logoutTime) : null,
        isLoggedOut: !!data.logoutTime
      };
    } else {
      const errorData = await response.json() as ApiErrorResponse;
      throw new Error(errorData.message || 'An error occurred');
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'An error occurred while fetching today\'s record.';
    return {
      loginTime: null,
      logoutTime: null,
      isLoggedOut: false,
      error: errorMessage
    };
  }
};