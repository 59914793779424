import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import SvgIcon from '../SVG/SvgIcons';
import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { IndexProps} from '../../Types';
 
const Index : React.FC<IndexProps> = ({ togglebutton, radiobutton, setRadioButton }) => {
 
  const isSmallMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const textSize = isSmallMobile ? '10px' : isTabletOrMobile ? '12px' : '15px';
  const iconSize = isSmallMobile ? 15 : isTabletOrMobile ? 18 : 20;
  return (
    <ButtonGroup className="d-flex w-100 mb-3">
      {togglebutton.map((radio, index) => (
        <ToggleButton className='d-flex align-items-center justify-content-center'
          key={index}
          id={`radio-${index}`}
          type="radio"
          name="radio"
          value={radio.value}
          checked={radiobutton === radio.value}
          onChange={(e) => setRadioButton(e.currentTarget.value)}
          style={{
            backgroundColor: radiobutton === radio.value ? '#0d6dfc' : 'white',
            color: radiobutton === radio.value ? 'white' : '#0d6dfc',
            borderColor: '#0d6dfc',
            fontSize: textSize,
           
          }}
        >
           {index === 0 && (
            <SvgIcon iconType="remoteWork" width={iconSize} height={iconSize} />
          )}
          {index === 1 && (
            <SvgIcon iconType="timeTracking" width={iconSize} height={iconSize} />
          )}
          <span className='font-semibold' style={{ marginLeft: '8px' }}>{radio.name}</span>
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};
 
export default Index;
 