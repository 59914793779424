import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import  settings from "../config/Config";
import { useUser } from "../contexts/UserContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WorkRecord, WorkDataPoint } from "../Types/Interface";
import CustomDateInput from "../Helper/CustomDateInput";
import CustomTooltip from "../Helper/CustomTooltip";
import { UserContextProps } from "../Types/PageTypes";

const EmployeeWorkHoursChart: React.FC = () => {
  const [workData, setWorkData] = useState<WorkDataPoint[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { user } = useUser() as UserContextProps;

  const employeeEmail = user ? user.email : null;

  useEffect(() => {
    if (!employeeEmail) return;

    const fetchWorkRecords = async () => {
      try {
        const selectedMonth = selectedDate.getMonth() + 1;
        const selectedYear = selectedDate.getFullYear();

        const url = new URL(
          `employee-work-tracker/records/${employeeEmail}`,
          settings.config.API_URL
        );
        url.searchParams.append("month", selectedMonth.toString());
        url.searchParams.append("year", selectedYear.toString());

        const response = await axios.get<WorkRecord[]>(url.toString());
        const records = response.data;

        const workHoursData = records.map((record: WorkRecord) => {
          const loginTime = new Date(record.loginTime);
          const logoutTime = record.logoutTime
            ? new Date(record.logoutTime)
            : null;

          let workingHours = 0;
          let formattedWorkingHours = "0hr 0m";

          if (logoutTime) {
            const diffInMs = logoutTime.getTime() - loginTime.getTime();
            const hours = Math.floor(diffInMs / (1000 * 60 * 60));
            const minutes = Math.floor(
              (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            workingHours = Math.min(hours, 9) + Math.min(minutes, 540) / 60;
            formattedWorkingHours = `${hours}hr ${minutes}m`;
          }

          return {
            date: loginTime.toISOString().split("T")[0],
            workingHours,
            formattedWorkingHours,
            loginTime: loginTime.toLocaleTimeString(),
            logoutTime: logoutTime
              ? logoutTime.toLocaleTimeString()
              : "Not logged out",
            isLoggedOut: !!logoutTime,
          };
        });

        const firstDay = new Date(selectedYear, selectedMonth - 1, 1);
        const lastDay = new Date(selectedYear, selectedMonth, 0);
        const allDates: string[] = [];

        for (
          let day = new Date(firstDay);
          day <= lastDay;
          day.setDate(day.getDate() + 1)
        ) {
          const dayOfWeek = day.getDay();

          if (dayOfWeek !== 6 && dayOfWeek !== 0) {
            const localDate = new Date(
              day.getTime() - day.getTimezoneOffset() * 60000
            );
            allDates.push(localDate.toISOString().split("T")[0]);
          }
        }

        const completeDataset = allDates.map((date) => {
          const existingData = workHoursData.find((data) => data.date === date);
          return (
            existingData || {
              date,
              workingHours: 0,
              formattedWorkingHours: "0hr 0m",
              loginTime: "N/A",
              logoutTime: "N/A",
              isLoggedOut: true,
            }
          );
        });

        setWorkData(completeDataset);
      } catch (error) {
        console.error("Error fetching work records:", error);
      }
    };

    fetchWorkRecords();
  }, [employeeEmail, selectedDate]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <Container style={{ fontWeight: 600 }}>
      <Row className="align-items-center mt-3" style={{ paddingTop: "10px" }}>
        <Col xs={12} md={8} lg={9}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={workData} margin={{ right: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(tick: string) =>
                  new Date(tick).getDate().toString()
                }
                interval={0}
                padding={{ right: 20 }}
              />
              <YAxis
                domain={[0, 12]}
                label={{
                  value: "Working Hours",
                  angle: -90,
                  position: "insideLeft",
                  dy: 50,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="linear"
                dataKey="workingHours"
                name="Date"
                stroke="#8884d8"
                dot={({ cx, cy, payload }) => {
                  const today = new Date().toISOString().split("T")[0];
                  const currentDate = new Date(payload.date)
                    .toISOString()
                    .split("T")[0];

                  const isPastDay = currentDate < today;
                  const isToday = currentDate === today;

                  return (
                    <circle
                      cx={cx}
                      cy={cy}
                      r={5}
                      stroke={isToday ? "green" : isPastDay ? "red" : "#8884d8"}
                      fill={isToday ? "green" : isPastDay ? "red" : "#8884d8"}
                      strokeWidth={2}
                    />
                  );
                }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col
          xs={12}
          md={4}
          lg={3}
          className="position-absolute mt-3"
          style={{ top: "20px", right: "-10px", fontWeight: 600 }}
        >
          <Form>
            <Form.Group>
              <Form.Label>Select Month and Year</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                showYearDropdown
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                customInput={<CustomDateInput />}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default EmployeeWorkHoursChart;