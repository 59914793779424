import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import {
  Buildings,
  Building,
  House,
  CheckCircleFill,
  CheckCircle,
  Clock,
} from "react-bootstrap-icons";
import { MapPin, Camera } from "lucide-react";
import { SidebarContext } from "../contexts/SidebarContext";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle } from "react-icons/fa";
import { useUser } from "../contexts/UserContext";
import Webcam from "react-webcam";
import settings from "../config/config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InSyncCheckIn = () => {
  const { isOpen } = useContext(SidebarContext);
  const navigate = useNavigate();
  const { user } = useUser();
  const [selectedOption, setSelectedOption] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cameraError, setCameraError] = useState(false);
  const webcamRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("info");
  const [isNearOffice, setIsNearOffice] = useState(false);
  const [nearestOffice, setNearestOffice] = useState(null);
  const [isDetectingLocation, setIsDetectingLocation] = useState(true);

  const [formData, setFormData] = useState({
    workType: null,
    city: null,
    userLat: null,
    userLon: null,
    username: user?.name || "",
    email: user?.email || "",
    loginTime: null,
    photo: null,
  });

  

  useEffect(() => {
    const initialize = async () => {
      await getUserLocation();
      initializeCamera();
    };

    initialize();
  }, []);

  const initializeCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setCameraError(true);
      toast.error("Please allow camera access to use this feature.");
      setShowAlert(true);
    }
  };

  const getUserLocation = () => {
    return new Promise((resolve) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lon: longitude });
            checkOfficeProximity(latitude, longitude);
            setIsDetectingLocation(false);
            resolve(true);
          },
          (error) => {
            console.error('Error getting user location:', error);
            toast.error('Please allow location access to use this feature.');
            setIsDetectingLocation(false);
            setShowAlert(true);
            resolve(false);
          },
          { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        toast.error("Your browser doesn't support geolocation. Please use a modern browser.");
        setIsDetectingLocation(false);
        setShowAlert(true);
        resolve(false);
      }
    });
  };

  const checkOfficeProximity = (lat, lon) => {
    const MAX_DISTANCE = 3;
    let nearest = null;
    let minDistance = Infinity;
  
  
    for (const [city, coords] of Object.entries(settings.officeLocations)) {
      const distance = calculateDistance(lat, lon, coords.lat, coords.lon);
  
      if (distance < minDistance) {
        minDistance = distance;
        nearest = city;
      }
  
      if (distance <= MAX_DISTANCE) {
        setIsNearOffice(true);
        setNearestOffice(city);
        setFormData((prevData) => ({
          ...prevData,
          userLat: lat,
          userLon: lon,
        }));
        return;
      }
    }
  
    
    setIsNearOffice(false);
    setNearestOffice(nearest);
    setFormData((prevData) => ({
      ...prevData,
      userLat: lat,
      userLon: lon,
    }));
  };

  const calculateDistance= (latitude1, longitude1, latitude2, longitude2) => {
    const earthRadiusKm = 6371;
    const deltaLatitude = ((latitude2 - latitude1) * Math.PI) / 180;
    const deltaLongitude = ((longitude2 - longitude1) * Math.PI) / 180;
    const area =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.cos((latitude1 * Math.PI) / 180) *
        Math.cos((latitude2 * Math.PI) / 180) *
        Math.sin(deltaLongitude / 2) *
        Math.sin(deltaLongitude / 2);
    const centralAngle = 2 * Math.atan2(Math.sqrt(area), Math.sqrt(1 - area));
    return earthRadiusKm * centralAngle;
  };
 
const handleCardClick = (option) => {
  setSelectedOption(option);
  setFormData((prevData) => ({
    ...prevData,
    workType: option,
  }));


  if (option === "office") {
    if (!isNearOffice) {
    
      toast.error("You are not at the office. Select Work From Home.");
      setAlertVariant("warning");
      setShowAlert(true);
      setSelectedOption(null); 
      setFormData((prevData) => ({
        ...prevData,
        workType: null,
      }));
    } else {
      console.log("User is near the office, proceeding with Work from Office.");
    }
  } else if (option === "home") {
    if (isNearOffice) {
      toast.error("You're at the office. Work From Home is unavailable.");
      setAlertVariant("warning");
      setShowAlert(true);
      setSelectedOption(null); 
      setFormData((prevData) => ({
        ...prevData,
        workType: null,
      }));
    } else {
      console.log("User is not near the office, proceeding with Work from Home.");
    }
  }
};
  const handleLocationClick = (city) => {
    setLocation(city);
    setFormData((prevData) => ({
      ...prevData,
      city: city,
    }));

    if (city !== nearestOffice) {
      toast.error(`You're not at the selected office location.`);
      setAlertVariant("warning");
      setShowAlert(true);
      
    }
  };

  const capturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setPhoto(imageSrc);
      setFormData((prevData) => ({
        ...prevData,
        photo: imageSrc,
      }));
    } else {
      console.error("Webcam is not accessible");
    }
  };
 
  const handleRetakePhoto = () => {
    setPhoto(null);
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
    }));
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };
 
  
  const handleSubmit = async () => {
    if (!userLocation) {
      toast.error("Please enable location services and try again.");
      getUserLocation();
      return;
    }
    if (!userLocation || !userLocation.lat || !userLocation.lon) {
      toast.error("Location data is not available. Please refresh the page and try again.");
      return;
    }

    if (selectedOption === "office" && !isNearOffice) {
      toast.error("You are not at an office location. Please select Work From Home.");
      setAlertVariant("warning");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 60000);
      return;
    }
  
    
    if (selectedOption === "office" && location !== nearestOffice) {
      toast.error("You're not at the selected office location.");
      setAlertVariant("warning");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 60000);
      return; 
    }
  
    
    if (selectedOption === "home" && isNearOffice) {
      toast.error("You're at the office. Work From Home is unavailable.");
      setAlertVariant("warning");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 60000);
      setSelectedOption(null); 
      setFormData((prevData) => ({
        ...prevData,
        workType: null,
      }));
      return; 
    }
  
   
    setSubmitting(true);
    const currentTime = Math.floor(Date.now() / 1000);
  
    
    const formData = new FormData();
    formData.append("employeeEmail", user.email);
    formData.append("employeeName", user.name);
    formData.append("workType", selectedOption);
    formData.append("loginTime", currentTime.toString());
    formData.append("location", `${userLocation.lat},${userLocation.lon}`);
  
   
    if (selectedOption === "office") {
      formData.append("branch", location);
  
      
      if (photo) {
        
        const byteString = atob(photo.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: "image/jpeg" });
        formData.append("photo", blob, "photo.jpg");
      } else {
        console.error("Photo is required for Work from Office");
        toast.error("Photo is required for Work from Office.");

        setAlertVariant("warning");
        setShowAlert(true);
        setSubmitting(false); 
        return; 
      }
    }
  
    try {
      
      const url = new URL('employee-work-tracker', settings.config.API_URL).toString();
  
     
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
  
      
      const responseText = await response.text();
      if (response.ok) {
        const result = JSON.parse(responseText);
        toast.success("Check-in successful!");
        setAlertVariant("primary");
        setShowAlert(true);
  
        
        setTimeout(() => {
          navigate("/logout");
        }, 2000);
      } else {
       
        let errorMessage = "Check-in failed. Please try again.";
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.message || errorMessage;
        } catch (e) {
          console.error("Error parsing error response:", e);
        }
        console.error("Check-in failed", errorMessage);
        toast.error(errorMessage);
        setAlertVariant("danger");
        setShowAlert(true);
      }
    } catch (error) {
      
      console.error("Error during check-in:", error);
      toast.error("Error during check-in. Please try again.");
      setAlertVariant("danger");
      setShowAlert(true);
    } finally {
     
      setSubmitting(false);
    }
  };
  
  return (
    <Container
      fluid
      className="text-center"
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        minHeight: "100vh",
        transition: "margin-left 0.3s",
        width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
      }}
    >
    <h3 className="font-semibold text-2xl text-start mb-2" style={{ display: 'flex', alignItems: 'center' }}>
  <Clock size={23} style={{ marginRight: '8px', strokeWidth: 2 }} />
  <span>Check In</span>
</h3>
<ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
  theme="light"
/>


      <Card
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          border: "2px solid #dee2e6",
          width: "100%",
          maxWidth: "1200px",
          marginTop: "0px",
        }}
        className="text-start mb-4"
      >
        <Card.Body className="text-start mb-3 mt-0">
          <Card.Title
            className="text-start mb-3 mt-0  font-semibold "
            style={{ fontWeight: "600" }}
          >
            InSync
            <hr
              style={{
                marginTop: "9px",
                border: "none",
                borderTop: "1px solid #ddd",
              }}
            />

          </Card.Title>
          <Row className="justify-content-around mb-3">
        {[
          { id: "office", title: "Work From Office", Icon: Buildings },
          { id: "home", title: "Work From Home", Icon: House },
        ].map(({ id, title, Icon }) => (
          <Col md={5} key={id}>
            <Card
              onClick={() => handleCardClick(id)}
              className={`d-flex align-items-center mb-0 ${
                selectedOption === id ? "border-secondary" : ""
              }`}
              style={{
                border: selectedOption === id ? "1px solid" : "1px solid #ddd",
                cursor: "pointer",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
              }}
            >
                  <div className="d-flex align-items-center w-100">
                    <div
                      style={{
                        backgroundColor: "#f7f7f7",
                        padding: "10px",
                        borderRadius: "50%",
                        marginRight: "15px",
                      }}
                    >
                      <Icon size={22} />
                    </div>
                    <Card.Title
                      className="text-bold mb-0 flex-grow-1"
                      style={{ fontWeight: "600" }}
                    >
                      {title}
                    </Card.Title>
                    <div style={{ marginLeft: "auto" }}>
                      {selectedOption === id ? (
                        <CheckCircle
                          size={24}
                          color={
                            selectedOption === id ? "var(--bs-primary)" : "#ddd"
                          }
                          fill={
                            selectedOption === id ? "var(--bs-primary)" : "#ddd"
                          }
                        />
                      ) : (
                        <div
                          style={{
                            width: "24px",
                            height: "24px",
                            border: "2px solid #ddd",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
 
          <hr
            style={{
              marginTop: "2px",
              border: "none",
              borderTop: "1px solid #ddd",
            }}
          />
          {selectedOption == "office" ? (
            <Row>
              <Col md={6} className="text-center  mt-4">
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ height: "300px", position: "relative" }}
                >
                  {photo ? (
                    <img
                      src={photo}
                      alt="Captured"
                      style={{
                        borderRadius: "0px",
                        width: "70%",
                        height: "270px",
                        objectFit: "cover",
                      }}
                    />
                  ) : loading ? (
                    <div
                      style={{
                        height: "250px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner animation="border" role="status" />
                    </div>
                  ) : cameraError ? (
                    <div
                      style={{
                        height: "250px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Camera size={48} className="text-muted mb-2" />
                      <p>Camera access denied or not available</p>
                    </div>
                  ) : (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      style={{
                        borderRadius: "0px",
                        width: "70%",
                        height: "270px",
                        objectFit: "cover",
                      }}
                      onUserMediaError={() => setCameraError(true)}
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={photo ? handleRetakePhoto : capturePhoto}
                      className="mt-4"
                      disabled={loading || cameraError}
                      style={{ fontWeight: "", width: "71%" }}
                    >
                      {photo ? "Retake Photo" : "Capture Photo"}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={6} className="text-center  mt-3" >
              <Form.Group className="mb-3 ms-5">
                  <div className="d-flex flex-column align-items-start mb-2">
                    <div className="d-flex align-items-center">
                      <MapPin className="text-primary " size={24} />
                      <Form.Label
                        className="mb-0 ms-2 h5 mt-0"
                        style={{ fontWeight: "" }}
                      >
                        Location
                      </Form.Label>
                    </div>
                    <div style={{ fontSize: "0.875rem", marginTop: "0.50rem" }}>
                      Select the office location you are currently in
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column gap-2"
                    style={{ width: "90%" }}
                  >
                    {["Coimbatore", "Chennai", "Bangalore"].map((city) => (
                      <Button
                      key={city}
                      variant={location === city ? "light" : ""}
                      className={`text-start py-2 px-5 d-flex justify-content-between align-items-center ${
                        location === city ? "border-primary" : "border"
                      }`}
                      onClick={() => handleLocationClick(city)}
                      style={{
                        backgroundColor: "#fff",
                        borderColor: "#d9d9d9",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
                        borderRadius: "10px",
                        padding: "20px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                        <span className="text-bold d-flex align-items-center">
                          <div
                            style={{
                              backgroundColor: "#f7f7f7",
                              padding: "10px",
                              borderRadius: "50%",
                              marginRight: "10px",
                            }}
                          >
                            <Buildings className=" " size={20} />
                          </div>
                          <span className="text-bold">{city}</span>
                        </span>
                        {location === city && (
                          <CheckCircleFill className="text-success" size={20} />
                        )}
                      </Button>
                    ))}
                  </div>
                </Form.Group>
 
                <Button
       variant="primary"
       className="mt-2 ms-2"
       style={{
         width: "82%",
         marginTop: "0px",
         fontWeight: "bold",
        }}
        onClick={handleSubmit}
        disabled={
          isDetectingLocation || 
          !selectedOption ||
          !userLocation ||
          (selectedOption === "office" && (!location || !photo || !isNearOffice)) ||
          submitting
        }
      >
        {submitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          "Submit"
        )}
      </Button>
              </Col>
            </Row>
          ) : (
            <Button
              variant="primary"
              className="mt-3"
              style={{
                width: "35%",
                marginTop: "0px",
                fontWeight: "bold",
                marginLeft: "33%",
              }}
              onClick={handleSubmit}
              disabled={
                isDetectingLocation ||
                !selectedOption ||
                !userLocation ||
                (selectedOption === "office" && (!location || !photo)) ||
                submitting
              }
            >
              {submitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
 
export default InSyncCheckIn;


