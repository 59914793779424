import React, { useEffect, useState, createContext, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { callMsGraph } from "../../config/graph";
import { Spinner, Container } from "react-bootstrap";
 
export const UserDetailsContext = createContext(null);
 
export const useUserDetails = () => useContext(UserDetailsContext);
 
const UserDetails = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          const userData = await callMsGraph(response.accessToken);
          setUserDetails({
            name: userData.displayName,
            email: userData.mail || userData.userPrincipalName,
            managerEmail: userData.managerEmail,
            managerName: userData.managerName,
          });
        } catch (error) {
          console.error("Error fetching user details:", error);
          instance.acquireTokenRedirect(loginRequest);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [instance, accounts]);
 
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }
 
  if (error) {
    return <div>Error loading user details. Please try again.</div>;
  }
 
  return (
    <UserDetailsContext.Provider value={userDetails}>
      {children}
    </UserDetailsContext.Provider>
  );
};
 
export default UserDetails;