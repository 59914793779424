import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import settings from "../config/config";
import { toast } from "react-toastify";
import axios from "axios";
import { Record } from "../Types";

export const capitalizeFirstLetter = (text: string): string => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
};


export const getBadgeVariant = (check: string): string => {
    if (typeof check !== "string") {
      return "secondary";
    }
    switch (check.toLowerCase()) {
      case settings?.constants?.status[0].toLowerCase() || null:
        return "primary";
      case settings?.constants?.status[1].toLowerCase() || null:
        return "danger";
      case settings?.constants?.status[2].toLowerCase() || null:
        return "dark";
      case settings?.constants?.status[3].toLowerCase() || null:
        return "warning";
      default:
        return "secondary";
    }
  };

  
  export const getIcon = (iconCheck: string) => {
    if (typeof iconCheck !== "string") {
        return CheckCircleOutlineIcon;
      }

    switch (iconCheck) {
      case settings?.constants?.status[0].toLowerCase() || null:
        return CheckCircleOutlineIcon;
      case settings?.constants?.status[1].toLowerCase() || null:
        return HighlightOffIcon;
      case settings?.constants?.status[2].toLowerCase() || null:
        return CancelRoundedIcon;
      case settings?.constants?.status[3].toLowerCase() || null:
        return InfoIcon;
      default:
        return null;
    }
  };

  
  export const handleApprove = async (uuid: string) => {
    try {
      await axios.get(
        `${settings.config.API_URL}wfh-activity-log/approve/${uuid}?isManagePage=true`
      );
      toast.success("Request approved successfully!", {
        className: "toast-primary",
      });
    } catch (error) {
      toast.error("Failed to approve the request.", {
        className: "toast-danger",
      });
    }
  };

  export const handleReject = async (uuid: string) => {
    try {
      await axios.get(
        `${settings.config.API_URL}wfh-activity-log/reject/${uuid}?isManagePage=true`
      );
      toast.success("Request rejected successfully!", {
        className: "toast-primary",
      });
    } catch (error) {
      toast.error("Failed to reject the request.", {
        className: "toast-danger",
      });
    }
  };

  export const handleCancel = async (uuid: string, record: Record) => {
    if (
      record.status === settings?.constants?.status[0].toLowerCase() ||
      record.status === settings?.constants?.status[1].toLowerCase() ||
      record.status === settings?.constants?.status[2].toLowerCase()
    ) {
      toast.error("Request already processed.", { className: "toast-danger" });
      return;
    }
    try {
      const response = await axios.get(
        `${settings.config.API_URL}wfh-activity-log/cancel/${uuid}`
      );
      toast.success("Request cancelled successfully!", {
        className: "toast-primary",
      });
    } catch (error) {
      toast.error("Failed to cancel the request.", {
        className: "toast-primary",
      });
    }
  };

 