import React, { useState, ChangeEvent } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useUser } from "../../src/contexts/UserContext";
import { QuestionCircle } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import settings from "../config/config";
 
const SupportModal: React.FC = () => {
  const { user } = useUser();
  const [showSupport, setShowSupport] = useState<boolean>(false);
  const [queryTitle, setQueryTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<string>("");
  const [messageError, setMessageError] = useState<string>("");
  const maxMessageLength = 2000;
 
  const handleShowSupport = () => setShowSupport(true);
 
  const handleCloseSupport = () => {
    setShowSupport(false);
    setQueryTitle("");
    setMessage("");
    setFile(null);
    setTitleError("");
    setMessageError("");
    setFileError("");
  };
 
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
 
    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
        setFileError("");
      } else {
        setFile(null);
        setFileError("Please upload only JPG or PNG files");
        e.target.value = "";
      }
    }
  };
 
  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
 
    let isValid = true;
 
    if (queryTitle.trim() === "") {
      setTitleError("Title is required");
      isValid = false;
    } else {
      setTitleError("");
    }
 
    if (message.trim() === "") {
      setMessageError("Please enter a message");
      isValid = false;
    } else {
      setMessageError("");
    }
 
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }
 
    const formData = new FormData();
    formData.append("email", user?.email || "no-email@example.com");
    formData.append("category", queryTitle);
    formData.append("message", message);
    if (file) {
      formData.append("file", file);
    }
 
    try {
      await axios.post(`${settings.config.API_URL}support/support-email`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Support request submitted!");
     
 
      handleCloseSupport();
    } catch (err) {
      toast.error("Failed to submit support request. Please try again.");
      console.error("Error submitting support request:", err);
    } finally {
      setIsSubmitting(false);
    }
  };
 
  return (
    <>
      <Button
        onClick={handleShowSupport}
        style={{
          color: "white",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "132%",
          marginLeft: "-4px",
          textAlign: "left",
          backgroundColor: "transparent",
          border: "none",
          fontSize: 20,
          fontFamily: "Inter, sans-serif",
          fontWeight: 500,
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = "#3F4A70";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = "transparent";
          e.currentTarget.style.transform = "scale(1)";
        }}
      >
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            marginRight: "1rem",
            marginLeft: "0.5rem",
            fontSize: 20,
          }}
        >
          <QuestionCircle style={{ fontSize: "19px", marginLeft: "2px" }} />
        </div>
        <span
          style={{
            fontSize: "17px",
            fontFamily: "Inter, sans-serif",
            fontWeight: 500,
            marginLeft: "-0.2rem",
          }}
        >
          Support
        </span>
      </Button>
 
      <Modal show={showSupport} onHide={handleCloseSupport} size="lg">
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <QuestionCircle style={{ fontSize: "24px" }} />
            Support Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4">
              <Form.Label
                style={{ fontFamily: "Inter, sans-serif", fontWeight: 600 }}
              >
                Title{" "}
                <span style={{ color: "#d32f2f", marginLeft: "4px" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter query title"
                value={queryTitle}
                onChange={(e) => setQueryTitle(e.target.value)}
                style={{ fontFamily: "Inter, sans-serif" }}
                required
              />
              {titleError && (
                <div
                  style={{
                    color: "#d32f2f",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {titleError}
                </div>
              )}
            </Form.Group>
 
            <Form.Group className="mb-4">
              <Form.Label
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  marginBottom: "7px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Query{" "}
                <span style={{ color: "#d32f2f", marginLeft: "4px" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                maxLength={maxMessageLength}
                placeholder="Enter your query"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ fontFamily: "Inter, sans-serif" }}
                required
              />
              {messageError && (
                <div
                  style={{
                    color: "#d32f2f",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {messageError}
                </div>
              )}
              <div
                className="mt-2"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  fontSize: "12px",
                  color: "#6c757d",
                  opacity: 0.8,
                  textShadow: "0.5px 0.5px 1px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span>
                  {maxMessageLength - message.length} characters remaining
                </span>
              </div>
            </Form.Group>
 
            <Form.Group className="mb-3">
              <Form.Label
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                Upload Screenshot
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  color: "#6c757d",
                  opacity: 0.8,
                  textShadow: "0.5px 0.5px 1px rgba(0, 0, 0, 0.1)",
                }}
              />
              <div
                className="mt-2"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 300,
                  fontSize: "12px",
                  color: "#6c757d",
                  opacity: 0.8,
                  textShadow: "0.5px 0.5px 1px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span>(JPG or PNG only)</span>
              </div>
              {fileError && (
                <div
                  style={{
                    color: "#d32f2f",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {fileError}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseSupport}
            style={{ fontFamily: "Inter, sans-serif", fontWeight: 600 }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              minWidth: "80px",
            }}
          >
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 
export default SupportModal;