const settings = {
  config: {
    API_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001",
    workLocationTitle: process.env.REACT_APP_WORK_LOCATION_TITLE || "Work Location",
    loggedInTitle: process.env.REACT_APP_LOGGED_IN_TITLE || "Logged In",
    loggedOutTitle: process.env.REACT_APP_LOGGED_OUT_TITLE || "Logged Out",
    todayTitle: process.env.REACT_APP_TODAY_TITLE || "Today",
  },
  
  defaults: {
    loginTime: process.env.REACT_APP_LOGIN_DEFAULT,
    logoutTime: process.env.REACT_APP_LOGOUT_DEFAULT,
    workType: process.env.REACT_APP_WORKTYPE_DEFAULT,
    workHour: "0h 0m",
    content: process.env.CARD_TITLE_DATA || "Unknown",
  },
  
  officeLocations: {
    Chennai: JSON.parse(process.env.OFFICE_LOCATIONS_CHENNAI || '{"lat":13.024707793382694,"lon":80.17671384162426}'),
    Bangalore: JSON.parse(process.env.OFFICE_LOCATIONS_BANGALORE || '{"lat":12.91405,"lon":77.63234}'),
    Coimbatore: JSON.parse(process.env.OFFICE_LOCATIONS_COIMBATORE || '{"lat":11.041975216476494,"lon":77.03773279201278}'),
  },

  constants: {
    status: ["Approved", "Rejected", "Cancelled", "Pending"],
    buttonNames: ["Remote Work", "Insync Records"],
    WfhPerMonth: 5,
    logoutWorkHours: "9h 00m",
    workPerDay: 9,
    workOption: ["office", "home"],
    resetDate: ["1900-01-01", "2100-12-31"],
    fileNames: ["wfh_record.csv", "insync_record.csv", "No records available", "records.csv"],
    FieldsWFH : ["id","uuid", "department", "position","updated_at", "created_at"],
    FieldsWorkTracker : ["id","photoUrl", "location", "updatedAt", "createdAt"],
    calendarData:["Yesterday", "Past Week","Past Month","All Records","All"],
    Calendar : ["All", "Today" ,"Yesterday", "Past Week","Past Month"],
    pageType:["MyRecord","ManageRecord"],
    wfhMessage:["The start date cannot be later than the end date.", "You cannot apply for WFH on the same dates as an existing request.","You cannot apply for more than 2 days of WFH in the same week.",
      "You cannot apply for more than 5 days of WFH in the same month.",
    ],
  }
};

export default settings;
