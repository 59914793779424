import * as Yup from "yup";

const validationSchema = Yup.object({
  dealNo: Yup.string().required("Deal No is required"),
  firstName: Yup.string()
    .test(
      "no-only-spaces",
      "First Name is required",
      (value) => !!value && value.trim().length > 0
    )
    .required("First Name is required"),
  LastName: Yup.string().required("Last Name is required"),
  dealSource: Yup.string().required("Deal Source is required"),
  dealPartner: Yup.string().required("Deal Partner is required"),
  dealCustomer: Yup.string().required("Deal Customer is required"),
  dealstatus: Yup.string().required("Deal Status is required"),
  dealStage: Yup.string().required("Deal Stage is required"),
  opportunityReceivedDate: Yup.date().typeError("Invalid date").required("Date is required"),
  expectedClosureDate: Yup.date().typeError("Invalid date").required("Date is required"),
  dealTitle: Yup.string().required("Deal Title is required"),
  winProbability: Yup.number()
    .typeError("Win Probability must be a number")
    .required("Win Probability is required")
    .min(0, "Must be at least 0")
    .max(100, "Cannot exceed 100"),
  dealTCV: Yup.number()
    .typeError("Deal TCV must be a number")
    .required("Deal TCV is required")
    .min(0, "Must be at least 0"),
  dealCurrency: Yup.string().required("Currency is required"),
  dealType: Yup.string().required("Deal Type is required"),
  dealDurationMonths: Yup.number()
    .typeError("Duration must be a number")
    .required("Duration is required"),
  lastUpdatedOn: Yup.string().required("Last Updated Date is required"),
  lastUpdateRemarks: Yup.string().required("Remarks are required"),
});

export default validationSchema;


