import { BiBarChartAlt2 } from "react-icons/bi";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { MdTableChart } from "react-icons/md";
import React from "react";

interface TabProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const Tab: React.FC<TabProps> = ({ activeTab, setActiveTab }) => {
  return (
    <ButtonGroup className="mb-4 flex justify-center">
      <ToggleButton
        type="radio"
        variant={activeTab === "table" ? "primary" : "outline-primary"}
        checked={activeTab === "table"}
        onClick={() => setActiveTab("table")}
        id={"table-tab"}
        value={"table"}
        className="w-1/2 md:w-1/4 font-bold flex items-center justify-center gap-2"
      >
        <MdTableChart /> Deal Table
      </ToggleButton>
      <ToggleButton
        type="radio"
        variant={activeTab === "graph" ? "primary" : "outline-primary"}
        checked={activeTab === "graph"}
        onClick={() => setActiveTab("graph")}
        id={"graph-tab"}
        value={"graph"}
        className="w-1/2 md:w-1/4 font-bold flex items-center justify-center gap-2"
      >
        <BiBarChartAlt2 /> Deal Insights
      </ToggleButton>
    </ButtonGroup>
  );
};

export default Tab;
