import React, { useState } from "react";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import TimePicker from "react-time-picker";
import { TodayData } from "../../Types";
import { dateTrimmer, totalHours, getTimeFromDate } from "../../utils/DateUtils";
import { User, LogIn, LogOut, Clock, Calendar } from "lucide-react";
import "react-time-picker/dist/TimePicker.css";
import settings from "../../config/Config";
import { toast } from "react-toastify";

interface RecordModelInsyncProps {
  selectedRecord: TodayData | null;
  showModal: boolean;
  handleClose: () => void;
  records: any;
}

const NotificationModel: React.FC<RecordModelInsyncProps> = ({
  selectedRecord,
  showModal,
  handleClose,
}) => {

  const [logoutTime, setLogoutTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  
  if (!selectedRecord) return null;
  if (!logoutTime && selectedRecord.logoutTime) {
    setLogoutTime(getTimeFromDate(selectedRecord.logoutTime));
  }

  const workedHours = totalHours(selectedRecord).todayWorkedHours;

 const handleLogoutTimeChange = (newTime: string) => {
    setLogoutTime(newTime);
  };
  

  const convertISTToISO = (istTime: string, date: string): string => {
    const [hours, minutes] = istTime.split(":").map(Number);

    const combinedDateTime = new Date(`${date}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);

    return combinedDateTime.toISOString();
  };
  
  
  const handleSubmit = async () => {
    if (!logoutTime) {
      setError("Logout time is required.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const logoutTimeISO = convertISTToISO(logoutTime, dateTrimmer(selectedRecord.createdAt));

      const response = await fetch(
        `${settings.config.API_URL}employee-work-tracker/update-logout-time/${selectedRecord.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            logoutTime: logoutTimeISO,
            uuid: Math.random().toString(36).substring(2),
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update logout time: ${response.statusText}`);
      }

      toast.success("Logout time updated successfully!");
      handleClose();
    } catch (err: any) {
      setError(err.message);
      toast.error(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary font-semibold">Record Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3">
        <ListGroup variant="flush" className="gap-1">
          <ListGroup.Item className="border-0 px-0">
            <Row className="align-items-center justify-content-between">
              <Col xs="auto" className="d-flex align-items-center gap-2">
                <User size={16} strokeWidth={2.5} />
                <strong className="font-semibold">Name:</strong>
              </Col>
              <Col xs={6} className="d-flex align-items-center justify-content-start">
                {selectedRecord.employeeName}
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="border-0 px-0">
            <Row>
              <Col className="d-flex align-items-center gap-2">
                <LogIn size={16} strokeWidth={2.5} />
                <strong className="font-semibold">Login Time:</strong>
              </Col>
              <Col xs={6} className="d-flex align-items-center justify-content-start">
                {getTimeFromDate(selectedRecord.loginTime)}
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="border-0 px-0">
            <Row>
              <Col className="d-flex align-items-center gap-2">
                <LogOut size={16} strokeWidth={2.5} />
                <strong className="font-semibold">Logout Time:</strong>
              </Col>
              <Col xs={6} className="d-flex align-items-center justify-content-start">
                <TimePicker
                  onChange={handleLogoutTimeChange}
                  value={logoutTime}
                  disableClock
                  clearIcon={null}
                  className="custom-time-picker"
                  format="h:m a"
                />
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="border-0 px-0">
            <Row>
              <Col className="d-flex align-items-center gap-2">
                <Clock size={16} strokeWidth={2.5} />
                <strong className="font-semibold">Total Time:</strong>
              </Col>
              <Col xs={6} className="d-flex align-items-center justify-content-start">
                {workedHours}
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item className="border-0 px-0">
            <Row>
              <Col className="d-flex align-items-center gap-2">
                <Calendar size={16} strokeWidth={2.5} />
                <strong className="font-semibold">Date:</strong>
              </Col>
              <Col xs={6} className="d-flex align-items-center justify-content-start">
                {dateTrimmer(selectedRecord.createdAt)}
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModel;
