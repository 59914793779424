import { Card } from "react-bootstrap";
import React from "react";
import { CardComponentProps } from "../../Types";

const CardComponent: React.FC<CardComponentProps> = ({
  title,
  content,
  img,
}) => {
  return (
    <Card
      className="my-2"
      style={{
        maxWidth: "100%",
        fontSize: "20px",
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        fontFamily: "Inter, sans-serif",
        border: "none",
      }}
    >
      <Card.Body className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center mb-2 gap-2">
          {img}
          <div>
            <Card.Title
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {title}
            </Card.Title>
            <Card.Text
              style={{ fontSize: "16px", color: "#6c757d", textAlign: "left" }}
            >
              {content}
            </Card.Text>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;
