import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import settings from "../../config/Config";
import { AllRecord, DownloadProps } from "../../Types";
import { useUser } from "../../contexts/UserContext";
import { IUser } from "../../Types/PageTypes";

const Download: React.FC<DownloadProps> = ({
  selectedStatusWFH,
  dateRangeWFH,
  searchTermWFH,
  radiobutton,
  selectedStatusInsync,
  dateRangeInsync,
  searchTermInsync,
  pageType,
}) => {
  const [wfhRecords, setWfhRecords] = useState<AllRecord[]>([]);
  const [trackerRecords, setTrackerRecords] = useState<AllRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const excludeFieldsWFH = settings.constants.FieldsWFH;
  const excludeFieldsWorkTracker = settings.constants.FieldsWorkTracker;

  const { user } = useUser() as { user: IUser };

  const formatDateForExport = (
    datetime: string,
    period: "AM" | "PM"
  ): string => {
    if (!datetime) return "";
    const [date] = datetime.split("T");
    return `${date}T12:00:00${period}`;
  };

  const fetchWFHRecords = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${settings.config.API_URL}wfh-activity-log/all/${user.email}`,
        {
          params: {
            status: selectedStatusWFH,
            start_date: dateRangeWFH?.start_date,
            end_date: dateRangeWFH?.end_date,
            search: searchTermWFH,
          },
        }
      );
      const formattedRecords = (response.data || []).map((record: any) => {
        const { first_name, last_name, ...rest } = record;
        return {
          name: `${first_name} ${last_name}`.trim(),
          ...rest,
        };
      });
      setWfhRecords(formattedRecords);
    } catch (error) {
      console.error("Failed to fetch WFH records:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWFHRecordsOne = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${settings.config.API_URL}wfh-activity-log/alladmin/${user.email}`,
        {
          params: {
            status: selectedStatusWFH,
            start_date: dateRangeWFH?.start_date,
            end_date: dateRangeWFH?.end_date,
            search: searchTermWFH,
            roleId: user.roleId,
          },
        }
      );

      const formattedRecords = (response.data || []).map((record: any) => {
        const { first_name, last_name, ...rest } = record;
        return {
          name: `${first_name} ${last_name}`.trim(),
          ...rest,
        };
      });
      setWfhRecords(formattedRecords);
    } catch (error) {
      console.error("Failed to fetch WFH records:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrackerRecords = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${settings.config.API_URL}employee-work-tracker/${user.email}/all-records`,
        {
          params: {
            roleId: user.roleId,
            status: selectedStatusInsync || "All Statuses",
            startDate: dateRangeInsync?.start_date,
            endDate: dateRangeInsync?.end_date,
            search: searchTermInsync,
          },
        }
      );
      const formattedRecords = (response.data || []).map((record: any) => {
        const { employeeEmail, employeeName, ...rest } = record;
        return {
          name: employeeName,
          email: employeeEmail,
          ...rest,
        };
      });
      setTrackerRecords(formattedRecords);
    } catch (error) {
      console.error("Failed to fetch tracker records:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrackerRecordsOne = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${settings.config.API_URL}employee-work-tracker/all`,
        {
          params: {
            roleId: user.roleId,
            status: selectedStatusInsync || "All Statuses",
            startDate: dateRangeInsync?.start_date,
            endDate: dateRangeInsync?.end_date,
            search: searchTermInsync,
          },
        }
      );
      const formattedRecords = (response.data || []).map((record: any) => {
        const { employeeEmail, employeeName, ...rest } = record;
        return {
          name: employeeName,
          email: employeeEmail,
          ...rest,
        };
      });
      setTrackerRecords(formattedRecords);
    } catch (error) {
      console.error("Failed to fetch tracker records:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (radiobutton === "1" && pageType === settings.constants.pageType[0]) {
      fetchWFHRecords();
    } else if (radiobutton === "2" && pageType === settings.constants.pageType[0]) {
      fetchTrackerRecords();
    } else if (radiobutton === "1" && pageType === settings.constants.pageType[1]) {
      fetchWFHRecordsOne();
    } else if (radiobutton === "2" && pageType === settings.constants.pageType[1]) {
      fetchTrackerRecordsOne();
    }
  }, [
    radiobutton,
    selectedStatusWFH,
    dateRangeWFH,
    searchTermWFH,
    selectedStatusInsync,
    dateRangeInsync,
    searchTermInsync,
  ]);

  const filterFields = (data: AllRecord[], excludeFields: string[]) => {
    return data.map((record) => {
      const filteredRecord: any = {};
      Object.keys(record).forEach((key) => {
        if (!excludeFields.includes(key)) {
          if (key === "start_date") {
            filteredRecord[key] = formatDateForExport(record[key], "AM");
          } else if (key === "end_date") {
            filteredRecord[key] = formatDateForExport(record[key], "PM");
          } else {
            filteredRecord[key] = record[key];
          }
        }
      });
      return filteredRecord;
    });
  };

  const convertToCSV = (data: AllRecord[]): string => {
    if (!data || data.length === 0) {
      return "";
    }
    const headers = Object.keys(data[0]);
    const csvRows = data.map((row) =>
      headers
        .map((field) => (row[field] == null ? '""' : `"${row[field]}"`))
        .join(",")
    );
    return [headers.join(","), ...csvRows].join("\n");
  };


  const formatToIST = (date: Date): string => {
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(date.getTime() + istOffset);
    return istDate.toISOString().split(".")[0].replace("T", "_").slice(0, -3);
  };


  const handleDownload = () => {
    let csvData = "";
    let filteredRecords = [];
    const now = new Date();
    const formattedDate = formatToIST(now);


    let fileName = `export_${formattedDate}.csv`;

    switch (radiobutton) {
      case "1":
        filteredRecords = filterFields(wfhRecords, excludeFieldsWFH);
        csvData = convertToCSV(filteredRecords);
        fileName = `wfh_records_${formattedDate}.csv`;
        break;
      case "2":
        filteredRecords = filterFields(
          trackerRecords,
          excludeFieldsWorkTracker
        );
        csvData = convertToCSV(filteredRecords);
        fileName = `employee_tracker_records_${formattedDate}.csv`;
        break;
      default:
        csvData = "No records to export.";
        break;
    }

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const isDisabled =
    loading ||
    (radiobutton === "1" && wfhRecords.length === 0) ||
    (radiobutton === "2" && trackerRecords.length === 0);

  return (
    <Button
      className="shadow-sm"
      onClick={handleDownload}
      disabled={isDisabled}
      style={{
        backgroundColor: "#0d6dfc",
        color: "black",
        border: "none",
        fontSize: isSmallMobile ? "10px" : isTabletOrMobile ? "12px" : "15px",
        width: isSmallMobile ? "70px" : "110px",
        height: isSmallMobile ? "32px" : "39px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: "5px",
      }}
    >
      <span
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ color: "#ffff" }}
      >
        <FaDownload />
        {loading ? "Loading..." : "Export"}
      </span>
    </Button>
  );
};

export default Download;
