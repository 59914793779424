import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TodayData } from "../../Types";
import { dateTrimmer, getTodayData, totalHours} from "../../Helper/DateUtils";

interface RecordModelInsyncProps {
  selectedRecord: TodayData | null;
  showModal: boolean;
  handleClose: () => void;
}

const RecordModelInsync: React.FC<RecordModelInsyncProps> = ({
  selectedRecord,
  showModal,
  handleClose,
}) => {
  if (!selectedRecord) return null;

  return (
    <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Record Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <strong>Name:</strong> {selectedRecord.employeeName}
      </p>
      <p>
        <strong>Login Time:</strong>{" "}
        {getTodayData(selectedRecord).loginTime}
      </p>
      <p>
        <strong>Logout Time:</strong>{" "}
        {getTodayData(selectedRecord).logoutTime}
      </p>
      <p>
        <strong>Total Time:</strong>{" "}
        {totalHours(selectedRecord).todayWorkedHours}
      </p>
      <p>
        <strong>Date:</strong> {dateTrimmer(selectedRecord.createdAt)}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default RecordModelInsync;
