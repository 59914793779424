import { FaSyncAlt } from "react-icons/fa";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormikTouched, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../axios";
import React, { useState, useEffect } from "react";

interface Deal {
  firstName: string;
  LastName: string;
  dealSource: string;
  dealPartner: string;
  dealCustomer: string;
  dealstatus: string;
  dealStage: string;
  opportunityReceivedDate: string;
  expectedClosureDate: string;
  dealTitle: string;
  winProbability: number;
  dealTCV: number;
  dealCurrency: string;
  dealType: string;
  dealDurationMonths: number;
  lastUpdatedOn: string;
  lastUpdateRemarks: string;
}

const EditForm: React.FC = () => {
  const { dealNo } = useParams<{ dealNo: string }>();
  const navigate = useNavigate();
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  

  useEffect(() => {
    axios
      .get(`/api/deals/${dealNo}`)
      .then((response) => setSelectedDeal(response.data))
      .catch((error) => console.error("Error fetching deal:", error));
  }, [dealNo]);

  const formik = useFormik<Deal>({
    initialValues: selectedDeal || {
      firstName: "",
      LastName: "",
      dealSource: "",
      dealPartner: "",
      dealCustomer: "",
      dealstatus: "",
      dealStage: "",
      opportunityReceivedDate: "",
      expectedClosureDate: "",
      dealTitle: "",
      winProbability: 0,
      dealTCV: 0,
      dealCurrency: "",
      dealType: "",
      dealDurationMonths: 0,
      lastUpdatedOn: "",
      lastUpdateRemarks: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("Firstname Required"),
      LastName: Yup.string().required("Lastname Required"),
      dealSource: Yup.string().required("Source Required"),
      dealPartner: Yup.string().required("Partner Required"),
      dealCustomer: Yup.string().required("Customer Required"),
      dealstatus: Yup.string().required("Status Required"),
      dealStage: Yup.string().required("Stage Required"),
      opportunityReceivedDate: Yup.string().required(
        "Opportunity Date Required"
      ),
      expectedClosureDate: Yup.string().required("Expected Date Required"),
      dealTitle: Yup.string().required("Title Required"),
      winProbability: Yup.number().required("Win Probability Required"),
      dealTCV: Yup.number().required("TCV Required"),
      dealCurrency: Yup.string().required("Currency Required"),
      dealType: Yup.string().required("Type Required"),
      dealDurationMonths: Yup.number().required("Duration Months Required"),
      lastUpdatedOn: Yup.string().required("Last Update Required"),
      lastUpdateRemarks: Yup.string().required("Update Remarks Required"),
    }),
    onSubmit: async (values) => {
     
      try {
        setLoading(true)
        console.log("Validation passed, updating deal...");
      const response =  await axios.post("/api/update", values);
        setTimeout(() => {
          setLoading(false);
          toast.success("Table Data Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
          });
        }, 5000);
      
        setTimeout(() => navigate("/dealmanagement"), 8000); 
      } catch (error) {
        setLoading(false);
        console.error("Error updating data:", error);
        toast.error("Failed to update data. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }      
    },
  });

  if (!selectedDeal) return <p>Loading...</p>;

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={formik.handleSubmit} className="p-3 mt-3">
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                First Name*
              </Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.firstName}*
                </div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Last Name*
              </Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                value={formik.values.LastName || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.LastName && formik.errors.LastName ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.LastName}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Source*
              </Form.Label>
              <Form.Control
                type="text"
                name="dealSource"
                value={formik.values.dealSource || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealSource && formik.errors.dealSource ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealSource}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Partner*
              </Form.Label>
              <Form.Control
                type="text"
                name="dealPartner"
                value={formik.values.dealPartner || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealPartner && formik.errors.dealPartner ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealPartner}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Customer*
              </Form.Label>
              <Form.Control
                type="text"
                name="dealCustomer"
                value={formik.values.dealCustomer || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealCustomer && formik.errors.dealCustomer ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealCustomer}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Status*
              </Form.Label>
              <Form.Select
                name="dealstatus"
                value={formik.values.dealstatus || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Status</option>
                <option value="In-progress">In-progress</option>
                <option value="Active">Active</option>
              </Form.Select>
              {formik.touched.dealstatus && formik.errors.dealstatus ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealstatus}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Stage*
              </Form.Label>
              <Form.Select
                name="dealStage"
                value={formik.values.dealStage || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Stage</option>
                <option value="L0">L0</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L3">L3</option>
              </Form.Select>
              {formik.touched.dealStage && formik.errors.dealStage ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealStage}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Opportunity Date*
              </Form.Label>
              <Form.Control
                type="date"
                name="opportunityReceivedDate"
                value={formik.values.opportunityReceivedDate || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.opportunityReceivedDate &&
              formik.errors.opportunityReceivedDate ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.opportunityReceivedDate}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Expected Closure Date*
              </Form.Label>
              <Form.Control
                type="date"
                name="expectedClosureDate"
                value={formik.values.expectedClosureDate || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.expectedClosureDate &&
              formik.errors.expectedClosureDate ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.expectedClosureDate}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Title*
              </Form.Label>
              <Form.Control
                type="text"
                name="dealTitle"
                value={formik.values.dealTitle || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealTitle && formik.errors.dealTitle ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealTitle}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Win Probability*
              </Form.Label>
              <Form.Control
                type="number"
                name="winProbability"
                value={formik.values.winProbability || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.winProbability && formik.errors.winProbability ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.winProbability}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Total Contract Value*
              </Form.Label>
              <Form.Control
                type="number"
                name="dealTCV"
                value={formik.values.dealTCV || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dealTCV && formik.errors.dealTCV ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealTCV}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Currency*
              </Form.Label>
              <Form.Control
                type="text"
                name="dealCurrency"
                value={formik.values.dealCurrency || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealCurrency && formik.errors.dealCurrency ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealCurrency}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Type*
              </Form.Label>
              <Form.Select
                name="dealType"
                value={formik.values.dealType || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Deal Type</option>
                <option value="FB">FB</option>
                <option value="T&M">T&M</option>
                <option value="BOH">BOH</option>
              </Form.Select>
              {formik.touched.dealType && formik.errors.dealType ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealType}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Duration Months*
              </Form.Label>
              <Form.Control
                type="number"
                name="dealDurationMonths"
                value={formik.values.dealDurationMonths || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.dealDurationMonths &&
              formik.errors.dealDurationMonths ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.dealDurationMonths}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Last Updated On*
              </Form.Label>
              <Form.Control
                type="date"
                name="lastUpdatedOn"
                value={formik.values.lastUpdatedOn || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
              />
              {formik.touched.lastUpdatedOn && formik.errors.lastUpdatedOn ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.lastUpdatedOn}*
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label className="block text-gray-600 text-sm font-medium">
                Last Update Remarks*
              </Form.Label>
              <Form.Control
                as="textarea"
                name="lastUpdateRemarks"
                value={formik.values.lastUpdateRemarks || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="border rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500"
                rows={4}
              />
              {formik.touched.lastUpdateRemarks &&
              formik.errors.lastUpdateRemarks ? (
                <div className="text-red-500 font-medium mt-1">
                  {formik.errors.lastUpdateRemarks}*
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <div className="text-center">
          <Button
            type="submit"
            variant="primary"
            className="w-32 h-12 mt-4 !font-semibold"
            disabled={loading}
          >
            {loading ? (
              <>
              <Spinner animation="border" size="sm"/>
              </>
            ) : (
              <>
              <FaSyncAlt className="inline-block mb-1 mr-1" /> Update
              </>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditForm;
