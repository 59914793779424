import { Button } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Paginate from "../Paginate/Paginate";
import React from "react";

interface Deal {
  dealNo?: string;
  firstName?: string;
  LastName?: string;
  owner?: string;
  dealSource?: string;
  dealPartner?: string;
  dealCustomer?: string;
  dealstatus?: string;
  dealStage?: string;
  opportunityReceivedDate?: string;
  expectedClosureDate?: string;
  dealTitle?: string;
  winProbability?: string;
  dealTCV?: number;
  dealCurrency?: string;
  dealType?: string;
  dealDurationMonths?: number;
  lastUpdatedOn?: string;
  lastUpdateRemarks?: string;
}

interface TableProps {
  data: Deal[];
  totalPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
}

const TableComponent: React.FC<TableProps> = ({
  data = [],
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const navigate = useNavigate();

  return (
    <div className="relative w-full overflow-x-auto mt-4 max-w-screen-2xl h-full">
      <div className="overflow-auto">
        <table className="text-center align-middle min-w-full border-collapse border-spacing-0">
          <thead>
            <tr>
              {[
                "Deal No",
                "Owner",
                "Source",
                "Partner",
                "Customer",
                "Status",
                "Stage",
                "Title",
                "Total Contract Value",
              ].map((header, index) => (
                <th
                  key={index}
                  className="p-3 whitespace-nowrap text-sm md:text-base font-serif min-w-[150px] text-black border-gray-300 border-b"
                >
                  {header}
                </th>
              ))}
              {/* <th className="p-3 text-black text-sm md:text-base min-w-[150px] font-serif sticky right-0 z-10 border-gray-300 border-b">
              Action
            </th> */}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr
                  key={item.dealNo || index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border-gray-300 border-b`}
                >
                  <td className=" p-3 border-b border-gray-300 font-semibold text-gray-600">
                    {item?.dealNo ?? "N/A"}
                  </td>
                  <td className="p-2 whitespace-normal break-words w-32 min-w-[200px] border-b border-gray-300 font-semibold text-gray-600">
                  {item?.firstName
                      ? `${item.firstName ?? ""} ${item.LastName}`
                      : "N/A"}
                  </td>

                  <td className=" border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealSource ?? "N/A"}
                  </td>
                  <td className=" border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealPartner ?? "N/A"}
                  </td>
                  <td className=" border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealCustomer ?? "N/A"}
                  </td>
                  <td className=" border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealstatus ?? "N/A"}
                  </td>
                  <td className=" border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealStage ?? "N/A"}
                  </td>
                  <td className="p-2 whitespace-normal break-words w-32 min-w-[200px] border-b border-gray-300  font-semibold text-gray-600">
                    {item?.dealTitle ?? "N/A"}
                  </td>
                  <td className="border-b border-gray-300 font-semibold text-gray-600">
                    {item?.dealTCV
                      ? `${item.dealCurrency ?? ""} $${item.dealTCV}`
                      : "N/A"}
                  </td>

                  <td className="sticky right-0 z-10 border-b border-gray-300 font-semibold text-gray-600 text-center">
                    <Button
                      variant="outline-primary"
                      onClick={() => navigate(`/edit/${item?.dealNo}`)}
                      className="mt-4 mb-4 w-20 h-10 flex items-center justify-center"
                      disabled={!item?.dealNo}
                    >
                      <FaPencilAlt className="text-lg" />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={19}
                  className="text-center p-4 text-gray-500 border-b border-gray-300"
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Paginate
        currentPage={currentPage}
        totalPages={totalPages > 0 ? totalPages : 1}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default TableComponent;
