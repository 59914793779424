import React, { useState } from "react";
import {
  Badge,
  Card,
  Dropdown,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import settings from "../../config/Config";
import illustration from "../../assets/illustration.jpg";
import { dateTrimmer } from "../../utils/DateUtils";
import { FaEye } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import { MyRecordCardProps, Record } from "../../Types";
import RecordModal from "../Header/RecordModel";
import { getBadgeVariant, getIcon } from "../../Helper";
import { handleApprove, handleCancel, handleReject } from "../../Helper";
import UserAvatar from '../../Helper/UserAvatar' 

const MyRecordCard: React.FC<MyRecordCardProps> = ({ records, pageType }) => {
  const isSmallMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<Record | null>(null);
  const { user } = useUser();
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      View Details
    </Tooltip>
  );

  if (!records || records.length === 0) {
    return (
      <img
        src={illustration}
        className="justify-content-center align-items-center w-6/12 mx-auto"
      />
    );
  }

  const handleViewDetails = (record: Record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  return (
    <>
      <ToastContainer style={{ width: "350px" }} />
      {records.map((record: Record) => {
        const IconComponent = getIcon(record.status.toLowerCase());
        const isPending =
          record.status.toLowerCase() ===
          settings.constants.status[3].toLowerCase();
          const name=record.first_name+" "+record.last_name
        return (
          <Card
            key={record.id}
            className="my-3 p-3 mt-1"
            style={{
              fontSize: "16px",
              backgroundColor: "#fff",
              borderRadius: "12px",
              boxShadow: "0 6px 18px rgba(0, 0, 0, 0.05)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              fontFamily: "Inter, sans-serif",
              border: "none",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <UserAvatar name={name}/>

                <div
                  className="font-semibold"
                  style={{
                    minWidth: "150px",
                    fontSize: isSmallMobile ? "15px" : "",
                  }}
                >
                  {record.first_name} {record.last_name}
                </div>
              </div>
              <div
                style={{ minWidth: "100px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Badge bg={getBadgeVariant(record.status)}>
                  {IconComponent && (
                    <IconComponent
                      className="me-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  )}
                  {record.status.charAt(0).toUpperCase() +
                    record.status.slice(1)}
                </Badge>
              </div>

              <div className="d-flex flex-column text-center">
                <div>
                  <EventAvailableOutlinedIcon
                    className="me-1"
                    style={{ height: "20px" }}
                  />
                  {`${dateTrimmer(record.start_date)} to ${dateTrimmer(
                    record.end_date
                  )}`}
                </div>
              </div>

              <div>
                {isPending ? (
                  <>
                    {(pageType === "ManageRecord" &&
                      Number(user.roleId) !== 3) ||
                    (pageType === "MyRecord" && Number(user.roleId) !== 3) ? (
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="d-flex align-items-center justify-content-center toggle-custom"
                          bsPrefix="custom-dropdown-toggle"
                        >
                          <FaEllipsisV style={{ transform: "rotate(90deg)" }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {pageType === "ManageRecord" &&
                            Number(user.roleId) !== 3 && (
                              <>
                                <Dropdown.Item
                                  onClick={() => handleApprove(record.uuid)}
                                >
                                  Approve Request
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleReject(record.uuid)}
                                >
                                  Reject Request
                                </Dropdown.Item>
                              </>
                            )}
                          {pageType === "MyRecord" &&
                            Number(user.roleId) !== 3 && (
                              <Dropdown.Item
                                onClick={() =>
                                  handleCancel(record.uuid, record)
                                }
                              >
                                Cancel Request
                              </Dropdown.Item>
                            )}
                          <Dropdown.Item
                            onClick={() => handleViewDetails(record)}
                          >
                            View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <Button
                          style={{ background: "transparent", border: "none" }}
                          onClick={() => handleViewDetails(record)}
                        >
                          <FaEye style={{ color: "#3F4A70" }} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </>
                ) : (
                  <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <Button
                      style={{ background: "transparent", border: "none" }}
                      onClick={() => handleViewDetails(record)}
                    >
                      <FaEye style={{ color: "#3F4A70" }} />
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </Card>
        );
      })}
      <RecordModal
        selectedRecord={selectedRecord}
        showModal={showModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default MyRecordCard;
