import React, { createContext, useState, ReactNode } from "react"; 

interface SidebarContextProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  isOpen: false,
  toggleDrawer: () => {},
});

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SidebarContext.Provider value={{ isOpen, toggleDrawer }}>
      {children}
    </SidebarContext.Provider>
  );
};
