import { useState, useEffect } from "react";
import axios from "axios";

export const useFetchData = (url:string, defaultValue:any[] = []) => {
  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      }
    )
      .catch((error) => {
        setError(error);
        setLoading(false);
  });
  }, [url]);

  return { data, error, loading };
};
