import React, { useState, useEffect, FormEvent, ChangeEvent, KeyboardEvent } from "react";
import {
  Pagination as BootstrapPagination,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { paginationProps } from "../../Types";

const Pagination : React.FC <paginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const [jumpToPage, setJumpToPage] = useState<number>(currentPage);

  useEffect(() => {
    setJumpToPage(currentPage);
  }, [currentPage]);

  const handleJumpToPage = (e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pageNumber = Math.min(Math.max(1, jumpToPage), totalPages);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setJumpToPage(Number(e.target.value));
  };

  useEffect( ()=>{
    const debounce = setTimeout(()=>{
      if (jumpToPage >= 1 && jumpToPage <= totalPages) {
        onPageChange(jumpToPage);
      }
    }, 1000);
    return ()=>clearTimeout(debounce);
  },[jumpToPage])

  return (
    <Container fluid className="mt-3">
      <Row className="align-items-center">
        <Col xs={12} md={4} className="d-flex align-items-center">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </Col>
        <Col xs={12} md={8} className="d-flex justify-content-end mb-2">
          <BootstrapPagination>
            <BootstrapPagination.First
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
            />
            <BootstrapPagination.Prev
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ marginLeft: "5px" }}
            />

            <Form onSubmit={handleJumpToPage} className="d-flex align-items-center">
              <Form.Group
                className="d-flex align-items-center px-2"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  padding: "1px 5px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Form.Label className="mb-0">Jump to:</Form.Label>
                <Form.Control
                  type="number"
                  value={jumpToPage}
                  onChange={handleInputChange}
                  min="1"
                  max={totalPages}
                  style={{
                    width: "70px",
                    border: "none", 
                    boxShadow: "none", 
                  }}
                />
              </Form.Group>
            </Form>

            <BootstrapPagination.Next
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: "10px" }}
            />
            <BootstrapPagination.Last
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: "10px" }}
            />
          </BootstrapPagination>
        </Col>
      </Row>
    </Container>
  );
};

export default Pagination;
