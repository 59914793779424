import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import { format, subDays, subWeeks, subMonths } from 'date-fns';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useMediaQuery } from 'react-responsive';
import settings from '../../config/Config';

 interface CalendarProps {
  onDateChange: (dates: { start_date: string | null; end_date: string | null }) => void;
  radiobutton: string;
  reset: boolean;
}


 interface IDateRange {
  start_date: string;
  end_date: string;
}


const Calendar: React.FC<CalendarProps> = ({ onDateChange, radiobutton, reset }) => {
  const [selectedOption, setSelectedOption] = useState<string>("Today");
  const [customDates, setCustomDates] = useState<IDateRange>({
    start_date: "",
    end_date: ""
  });
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showCustomMenu, setShowCustomMenu] = useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const dropdownButtonStyle: React.CSSProperties = {
    color: 'black',
    width: isSmallMobile ? '120px' : isTabletOrMobile ? '180px' : '210px',
    fontSize: isSmallMobile ? '12px' : isTabletOrMobile ? '14px' : '16px',
    border: '1px solid #dee2e6',
    background: '#ffff',
  };

  useEffect(() => {
    setSelectedOption("Today");
  }, [reset, radiobutton]);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    setShowCustomMenu(false);

    let startDate: string | null = null;
    let endDate: string | null = null;

    switch (option) {
      case settings?.constants?.calendarData[0] || 'Yesterday':
        startDate = endDate = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        break;
      case settings?.constants?.calendarData[1] || 'Past Week':
        startDate = format(subWeeks(new Date(), 1), 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
        break;
      case settings?.constants?.calendarData[2] || 'Past Month':
        startDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
        break;
      case settings?.constants?.calendarData[4] || 'All':
        startDate = settings?.constants?.resetDate[0] || "0000-00-00";
        endDate = settings?.constants?.resetDate[1] || "0000-00-00";
        break;
      case 'Custom Range':
        setShowCustomMenu(true);
        return;
      default:
        startDate = endDate = format(new Date(), 'yyyy-MM-dd');
    }

    onDateChange({ start_date: startDate, end_date: endDate });
    setShowDropdown(false);
  };

  const handleCustomDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomDates((prevDates) => ({
      ...prevDates,
      [name]: value
    }));
  };

  const handleCustomDateSubmit = () => {

    if (!customDates.start_date || !customDates.end_date) {
      alert("Both Start Date and End Date are required.");
      return;
    }

    onDateChange(customDates);
    setCustomDates({ start_date: "", end_date: "" });
    setShowDropdown(false);
    setShowCustomMenu(false); 
    setSelectedOption("Custom Range");
  };

  return (
    <Dropdown
      className="rounded-lg bg-white shadow-sm"
      show={showDropdown}
      onToggle={(isOpen) => setShowDropdown(isOpen)}
    >
      <Dropdown.Toggle
        id="dropdown-basic-button"
        variant="light"
        className="d-flex justify-content-between align-items-center"
        style={dropdownButtonStyle}
      >
        <span className="d-flex align-items-center gap-2">
          <DateRangeOutlinedIcon fontSize="medium" />
          <span>{selectedOption}</span>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ minWidth: "200px" }}
        onClick={(e) => e.stopPropagation()} 

      >
        {settings.constants.Calendar.map((option, index) => (
          <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
            {option}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          className="position-relative"
          onMouseEnter={() => setShowCustomMenu(true)}
          onClick={(e) => e.stopPropagation()}
        >
          Custom Range
          {showCustomMenu && (
            <div
              className="custom-date-submenu p-3 position-absolute"
              style={{
                right: '-260px',
                top: '-150px',
                minWidth: '250px',
                zIndex: 1,
                backgroundColor: 'white',
                border: '1px solid #dee2e6',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                borderRadius: "5px"
              }}
              onClick={(e) => e.stopPropagation()} 
            >
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="start_date"
                  value={customDates.start_date}
                  onChange={(e) => {
                    handleCustomDateChange(e as React.ChangeEvent<HTMLInputElement>);
                    e.stopPropagation(); 
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="end_date"
                  value={customDates.end_date}
                  onChange={(e) => {
                    handleCustomDateChange(e as React.ChangeEvent<HTMLInputElement>);
                    e.stopPropagation();
                  }}
                />
              </Form.Group>
              <Button variant="primary" className="mt-2"  onClick={() => {
              handleCustomDateSubmit();
              setShowDropdown(false); 
            }}>
                Apply
              </Button>
            </div>
          )}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Calendar;
