
export const msalConfig = {
    auth: {
      clientId: "96edb2f0-ab5a-4d7e-912f-71fcb5e755c9", 
      authority: "https://login.microsoftonline.com/d89a7ac3-a7fb-456b-ad89-7fcb9250801c",
      redirectUri: "https://insync.codincity.com/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true, 
    },
  };
  
  export const loginRequest = {
    scopes: ["User.Read"], 
  };