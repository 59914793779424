import _ from "lodash";
import { Navbar as BootstrapNavbar, Button } from "react-bootstrap";
import { SidebarContext } from "../../contexts/SidebarContext";
import { useUser } from "../../contexts/UserContext";
import { useContext } from "react";
import UserAvatar from '../../Helper/UserAvatar'
const CustomNavbar = () => {
  const { isOpen } = useContext(SidebarContext);
  const { user } = useUser();
 
  return (
    <div>
      <BootstrapNavbar
        fixed="top"
        style={{
          marginLeft: isOpen ? "250px" : "60px",
          width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
          height: "60px",
          transition: "width-left 0.3s",
          boxShadow: "none",
          backgroundColor: "#ffff",
          borderBottom: "2px solid #f4f4f4",
          zIndex: 1000,
        }}
      >
        <span className="font-semibold text-3xl ml-5" style={{fontFamily:'inter'}}>Codintools</span>
        
          <Button className="justify-content-center align-items-center"
            variant="link"
            aria-label="user-logo"
            style={{
              marginLeft: "auto",
              padding: "10px",
              display: "flex",
              textDecoration: "none",
            }}
          >
            <UserAvatar user={user}/>
          </Button>
      </BootstrapNavbar>
    </div>
  );
};

export default CustomNavbar;
