import { Container, Spinner } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import Index from "../components/Toggle/index";
import { SidebarContext } from "../contexts/SidebarContext";
import Search from "../components/Header/search";
import Filter from "../components/Header/filter";
import Refresh from "../components/Header/refresh";
import Download from "../components/Header/download";
import Calendar from "../components/Header/calendar";
import MyRecordCard from "../components/Card/MyRecordCard";
import MyRecordInsync from "../components/Card/MyRecordInsync";
import settings from "../config/config";
import { useUser } from "../contexts/UserContext";
import { format } from "date-fns";
import Pagination from "../components/Header/pagination";
import SvgIcon from "../components/SVG/svgIcons";
import { IUser, IDateRange, IRecordData } from "../Types/pageTypes";

const MyRecord: React.FC = () => {
  const { isOpen } = useContext(SidebarContext);
  const [radiobutton, setRadioButton] = useState<string>("1");
  const { user } = useUser() as { user: IUser };
  const [loading, setLoading] = useState<boolean>(true);

  const [recentRecords, setRecentRecords] = useState<IRecordData | null>(null);

  const [WorkData, setWorkData] = useState<IRecordData | null>(null);

  const [resetToggle, setResetToggle] = useState<boolean>(false);

  const [searchTermWFH, setSearchTermWFH] = useState<string>("");
  const [selectedStatusWFH, setSelectedStatusWFH] =
    useState<string>("All Statuses");
  const [dateRangeWFH, setDateRangeWFH] = useState<IDateRange>({
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  });

  const [currentPageWFH, setCurrentPageWFH] = useState<number>(1);
  const [recordsPerPageWFH] = useState<number>(10);
  const [totalPagesWFH, setTotalPagesWFH] = useState<number>(1);

  const [searchTermInsync, setSearchTermInsync] = useState<string>("");
  const [selectedStatusInsync, setSelectedStatusInsync] =
    useState<string>("All Statuses");
  const [dateRangeInsync, setDateRangeInsync] = useState<IDateRange>({
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  });
  const [currentPageInsync, setCurrentPageInsync] = useState<number>(1);
  const [recordsPerPageInsync] = useState<number>(10);
  const [totalPagesInsync, setTotalPagesInsync] = useState<number>(1);

  const handlePageChangeWFH = (pageNumber: number) => {
    setCurrentPageWFH(pageNumber);
  };

  const handlePageChangeInsync = (pageNumber: number) => {
    setCurrentPageInsync(pageNumber);
  };

  const togglebutton = [
    {
      name: settings.constants.buttonNames[0],
      value: "1",
    },
    {
      name: settings.constants.buttonNames[1],
      value: "2",
    },
  ];

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        
        const recentData = await fetch(
          `${settings.config.API_URL}wfh-activity-log/${user.email}?page=${currentPageWFH}&limit=${recordsPerPageWFH}&status=${selectedStatusWFH}&start_date=${dateRangeWFH.start_date}&end_date=${dateRangeWFH.end_date}&search=${searchTermWFH}`
        );
        const data = await recentData.json();
        setRecentRecords(data);
        setLoading(false);
      };
      fetchData();
    }
  }, [
    user,
    currentPageWFH,
    recordsPerPageWFH,
    selectedStatusWFH,
    dateRangeWFH,
    searchTermWFH,
  ]);

  const recentError = recentRecords?.error;

  useEffect(() => {
    if (recentRecords && recentRecords.total && recordsPerPageWFH) {
      setTotalPagesWFH(Math.ceil(recentRecords.total / recordsPerPageWFH));
    } else {
      setTotalPagesWFH(1);
    }
  }, [recentRecords, recordsPerPageWFH]);

  const handleSearchWFH = (term: string) => {
    setSearchTermWFH(term);
    setCurrentPageWFH(1);
    if (term === '') {
      handleRefreshWFH();
    }
  };
  const handleSearchInsync = (term: string) => {
    setSearchTermInsync(term);
    setCurrentPageInsync(1);
    if (term === '') {
      handleRefreshWFH();
    }
  };



const handleRefreshWFH = () => {
  const todayDate = format(new Date(), 'yyyy-MM-dd');
  setDateRangeWFH({
    start_date: todayDate,
    end_date: todayDate,
  });
  setSearchTermWFH(""); 
  setSelectedStatusWFH("All Statuses"); 
  setCurrentPageWFH(1); 
  setTotalPagesWFH(1);
  setResetToggle((prev) => !prev); 
};

const handleRefreshInsync = () => {
  const todayDate = format(new Date(), 'yyyy-MM-dd');
  setDateRangeInsync({
    start_date: todayDate,
    end_date: todayDate,
  });
  setSearchTermInsync(""); 
  setSelectedStatusInsync("All Statuses"); 
  setCurrentPageInsync(1);
  setTotalPagesInsync(1); 
  setResetToggle((prev) => !prev);
};


  const handleDateChangeWFH = (range: IDateRange) => {
    setDateRangeWFH(range);
    setCurrentPageWFH(1);
  };

  const handleDateChangeInsync = (range: IDateRange) => {
    setDateRangeInsync(range);
    setCurrentPageInsync(1);
  };

  const handleStatusSelectWFH = (status: string) => {
    setSelectedStatusWFH(status);
    setCurrentPageWFH(1);
  };

  const handleStatusSelectInsync = (status: string) => {
    setSelectedStatusInsync(status);
    setCurrentPageInsync(1);
  };

  useEffect(() => {
    const fetchWorkData = async () => {
      if (user) {
        const workDataUrl = `${settings.config.API_URL}employee-work-tracker/${user.email}?page=${currentPageInsync}&limit=${recordsPerPageInsync}&status=${selectedStatusInsync}&startDate=${dateRangeInsync.start_date}&endDate=${dateRangeInsync.end_date}&search=${searchTermInsync}`;
        const data = await fetch(workDataUrl);
        const result = await data.json();
        setWorkData(result);
        setLoading(false);
      }
    };

    fetchWorkData();
  }, [
    user,
    currentPageInsync,
    recordsPerPageInsync,
    selectedStatusInsync,
    dateRangeInsync,
    searchTermInsync,
  ]);

  useEffect(() => {
    if (WorkData && WorkData.total && recordsPerPageInsync) {
      setTotalPagesInsync(Math.ceil(WorkData.total / recordsPerPageInsync));
    } else {
      setTotalPagesInsync(1); 
    }
  }, [WorkData, recordsPerPageInsync]);

  const filterOptions = radiobutton === "1" ? settings.constants.status : settings.constants.workOption;
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "450px" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <Container
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
        transition: "margin-left 0.3s",
        width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
      }}
    >
      <div className="d-flex mb-3 align-items-center gap-2">
        <SvgIcon iconType="records" width={20} height={20} />
        <span className="font-semibold text-2xl">My Records</span>
      </div>

      <Container
        style={{
          border: "2px solid #dee2e6",
          borderRadius: "8px",
          padding: "20px",
          background: "#ffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Container
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: 0,
            background: "#ffff",
            width: "100%",
          }}
        >
          <Index
            togglebutton={togglebutton}
            radiobutton={radiobutton}
            setRadioButton={setRadioButton}
          />
          <Container className="d-flex flex-nowrap p-3 gap-3 justify-content-center align-items-center">
            {radiobutton === "1" ? (
              <>
                <Search
                  key={radiobutton}
                  onSearch={handleSearchWFH}
                  reset={resetToggle}
                  resetSearchTrigger={resetToggle}
                />
                <Filter
                  options={filterOptions}
                  onSelectStatus={handleStatusSelectWFH}
                  radiobutton={radiobutton}
                  reset={resetToggle}
                />
                <Calendar
                  onDateChange={handleDateChangeWFH}
                  radiobutton={radiobutton}
                  reset={resetToggle}
                />
                <Refresh onRefresh={handleRefreshWFH} />
              </>
            ) : (
              <>
                <Search
                  key={radiobutton}
                  onSearch={handleSearchInsync}
                  reset={resetToggle}
                  resetSearchTrigger={resetToggle}
                />
                <Filter
                  options={filterOptions}
                  onSelectStatus={handleStatusSelectInsync}
                  radiobutton={radiobutton}
                  reset={resetToggle}
                />
                <Calendar
                  onDateChange={handleDateChangeInsync}
                  radiobutton={radiobutton}
                  reset={resetToggle}
                />
                <Refresh onRefresh={handleRefreshInsync} />
              </>
            )}
            <Download
              wfhRecords={recentRecords?.data || []}
              insyncRecords={WorkData?.data || []}
              radiobutton={radiobutton}
            />
          </Container>
          <Container style={{ width: "100%" }}>
            {radiobutton === "1" ? (
              <>
                <MyRecordCard
                  records={recentRecords?.data || []}
                  pageType="MyRecord"
                />

                {totalPagesWFH > 1 && (
                  <Pagination
                    currentPage={currentPageWFH}
                    totalPages={totalPagesWFH}
                    onPageChange={handlePageChangeWFH}
                  />
                )}
              </>
            ) : (
              <>
                <MyRecordInsync
                  records={WorkData?.data || []}
                  error={recentError}
                  pageType="MyRecord"
                />
                {totalPagesInsync > 1 && (
                  <Pagination
                    currentPage={currentPageInsync}
                    totalPages={totalPagesInsync}
                    onPageChange={handlePageChangeInsync}
                  />
                )}
              </>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default MyRecord;
