import React from "react";
import { Modal, Button, ListGroup, Row, Col } from "react-bootstrap";
import { Record } from "../../Types";
import { dateTrimmer } from "../../utils/DateUtils";
import { User, CheckCircle, Calendar } from 'lucide-react';
interface RecordModalProps {
  selectedRecord: Record | null;
  showModal: boolean;
  handleClose: () => void;
}

const RecordModal: React.FC<RecordModalProps> = ({
  selectedRecord,
  showModal,
  handleClose,
}) => {
  if (!selectedRecord) return null;

  return (
    <Modal show={showModal} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title className="text-primary">Record Details</Modal.Title>
    </Modal.Header>
    <Modal.Body className="px-3">
      <ListGroup variant="flush" className="gap-2">
        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <User size={16} strokeWidth={2} />
              <strong>Name:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {selectedRecord.first_name} {selectedRecord.last_name}
            </Col>
          </Row>
        </ListGroup.Item>
        
        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <CheckCircle size={16} strokeWidth={2} />
              <strong>Status:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {selectedRecord.status.charAt(0).toUpperCase() +
                selectedRecord.status.slice(1)}
            </Col>
          </Row>
        </ListGroup.Item>
        
        <ListGroup.Item className="border-0 px-0">
          <Row>
            <Col className="d-flex align-items-center gap-2">
              <Calendar size={16} strokeWidth={2} />
              <strong>Date Range:</strong>
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-start">
              {dateTrimmer(selectedRecord.start_date)} to{" "}
              {dateTrimmer(selectedRecord.end_date)}
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default RecordModal;


