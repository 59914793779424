import React from "react";
import { Button } from "react-bootstrap";
import { FaSync } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { refreshProps } from "../../Types";

const Refresh : React.FC<refreshProps> = ({onRefresh}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 480px)" });
 
  return (
    <Button
    className="shadow-sm"
      style={{
        backgroundColor: "#ffff",
        color: "black",
        width: isSmallMobile ? "55px" : "100px",
        fontSize: isSmallMobile ? "10px" : isTabletOrMobile ? "12px" : "15px",
        height: isSmallMobile ? "30px" :"37px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        border: "1px solid #0d6dfc"
      }}
      onClick={onRefresh}
     
    >
      <span style={{color:"#0d6dfc"}} className="d-flex justify-content-center align-items-center gap-2">
      <FaSync/>
      Reset
      </span>
     
    </Button>
  );
};
 
export default Refresh;