import React, { useState } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import NotificationModel from "./NotificationModel";
import { TodayData } from "../../Types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface BannerProps {
  variant?: "primary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
  message: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonVariant?: string;
  records: TodayData;
}

const Banner: React.FC<BannerProps> = ({
  variant = "info",
  message,
  buttonLabel = "Take Action",
  buttonAction,
  buttonVariant = "primary",
  records,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    if (buttonAction) {
      buttonAction();
    } else if (records) {
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <ToastContainer />
      <Alert
        variant={variant}
        className="d-flex justify-content-between align-items-center py-3 shadow-sm"
        style={{
          borderRadius: "8px",
          backgroundColor: variant === "info" ? "#d9ecff" : undefined,
          color: variant === "info" ? "#084298" : undefined,
        }}
      >
        <Container fluid>
          <Row className="align-items-center w-100">
            <Col xs={10}>
              <p className="mb-0 fw-semibold text-truncate" title={message} style={{ fontSize: "1rem" }}>
                {message}
              </p>
            </Col>
            <Col xs={2} className="text-end">
              <Button
                variant={buttonVariant}
                onClick={handleButtonClick}
                className="fw-semibold shadow-sm"
                style={{
                  fontSize: "0.875rem",
                  padding: "0.375rem 0.75rem",
                  borderRadius: "4px",
                }}
              >
                {buttonLabel}
              </Button>
            </Col>
          </Row>
        </Container>
      </Alert>
      {records && (
        <NotificationModel
          selectedRecord={records}
          records={[records]}
          showModal={showModal}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default Banner;
