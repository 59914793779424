import { Logout as LogoutIcon, Menu as MenuIcon } from "@mui/icons-material";
import { Container, ListGroup, Button } from "react-bootstrap";
import { SidebarContext } from "../../contexts/SidebarContext";
import { useMediaQuery } from "react-responsive";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import settings from "../../config/Config";
import { useUser } from "../../contexts/UserContext";
import Logoutmsal from "../../pages/LoginPage/Logoutmsal";
import axios from "axios";
import SupportModal from '../../support/Supportmodel';
import SvgIcon from "../SVG/SvgIcons";

interface MenuItem {
  name: string;
  icon: keyof typeof iconMapping;
}

const iconMapping: { [key: string]: JSX.Element } = {
  Help: <SvgIcon iconType="dashboard" width={20} height={20} />,
  UploadFile: <SvgIcon iconType="timeTracking" width={20} height={20} />,
  Edit: <SvgIcon iconType="remoteWork" width={20} height={20} />,
  sync: <SvgIcon iconType="records" width={20} height={20} />,
  PermMedia: <SvgIcon iconType="admin" width={20} height={20} />,
};
function Sidebar() {
  const { isOpen, toggleDrawer } = useContext(SidebarContext);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const { user } = useUser();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`${settings.config.API_URL}menu-items/`);
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };
    fetchMenuItems();
  }, []);

  const isManager = user?.showMenu || false;

  useEffect(() => {
    if (isMobile && isOpen) {
      toggleDrawer();
    }
  }, [isMobile]);

  const handleOutsideClick = (e: MouseEvent) => {
    if (isMobile && isOpen && !(e.target as HTMLElement).closest(".sidebar")) {
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (isOpen && isMobile) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, isMobile]);

  const handleNavigation = (itemName : string) => {
    setSelectedItem(itemName);
    switch (itemName) {
      case "Remote Work":
        navigate("/work-from-home");
        break;
      case "Insync":
        navigate("/Attendance");
        break;
      case "My Records":
        navigate("/myrecords");
        break;
      case "Manage Records":
        if (isManager) {
          navigate("/managerecords");
        } else {
          alert("You do not have permission to access this page.");
        }
        break;
      case "Home":
        navigate("/dashboard");
        break;
      default:
        navigate("/");
        break;
    }
  };

  return (
    <>
      {isMobile && isOpen && (
        <Container
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={toggleDrawer}
        ></Container>
      )}

      <div
        className="sidebar"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: isOpen ? 250 : 60,
          height: "100vh",
          backgroundColor: "#272D4B",
          transition: "width 0.3s",
          zIndex: 1000,
          overflow: "hidden",
        }}
      >
        <Container
          fluid
          style={{
            width: isOpen ? 200 : 60,
            backgroundColor: "#272D4B",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            transition: "width 0.3s",
          }}
        >
          <Container
            fluid
            className="d-flex align-items-center justify-content-between"
            style={{ padding: "15px", gap: "10px" }}
          >
            <Button
              variant="link"
              style={{
                padding: 0,
                marginLeft: isOpen ? 0 : 0,
                transition: "margin-left 0.1s",
              }}
              onClick={toggleDrawer}
            >
              <MenuIcon
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  color: "white",
                  display: isOpen ? "none" : "block",
                }}
              />
            </Button>
            {isOpen && (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%", 
      }}
    >
      <img
        src={"/SVG/logo.svg"}
        alt="Logo"
        className="p-2 ml-5"
        style={{
          height: "auto",
        }}
      />
    </div>
  )}

          </Container>

          <hr style={{ margin: 0, borderColor: "#4A4A4A" }} />

          <ListGroup className="ps-2">
            {menuItems
              .filter((item) => !(item.name === "Manage Records" && !isManager))
              .map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor:
                      selectedItem === item.name ? "#3F4A70" : "transparent",
                    color: "white",
                    justifyContent: isOpen ? "initial" : "center",
                    transition: "background-color 0.3s, transform 0.3s",
                    border: selectedItem === item.name ? "1px solid #272D4B" : "none",
                    boxShadow: selectedItem === item.name ? "0 0 5px rgba(0, 0, 0, 0.3)" : "none",
                    cursor: "pointer",
                    width: "121%",
                    borderRadius: "5px", 
                    transform:
                      selectedItem === item.name ? "none" : "translateY(0)",
                  }}
                  onClick={() => handleNavigation(item.name)}
                  onMouseEnter={(e) => {
                    if (selectedItem !== item.name) {
                      e.currentTarget.style.transform = "translateY(-1  px)";
                    }
                    e.currentTarget.style.backgroundColor ="#4A567C";
                 
                      e.currentTarget.style.transform="translateY(0)";
                
                  }}
                  onMouseLeave={(e) => {
                    if (selectedItem !== item.name) {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }
                    e.currentTarget.style.transform = "translateX(0px)";
                    
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      minWidth: 0,
                      marginRight: isOpen ? "1rem" : "auto",
                      marginLeft: !isOpen ? "-0.3rem" : "",
                      display: "flex",
                    }}
                  >
                    {iconMapping[item.icon]}
                  </div>
                  {isOpen && (
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: "Inter, sans-serif",
                        color: "white",
                        fontWeight: 500,
                        marginLeft: "0px",
                      }}
                    >
                      {item.name}
                    </span>
                  )}
                </ListGroup.Item>
              ))}
          </ListGroup>

          <Container
            style={{
              marginTop: "auto",
              marginBottom: "10px",
              width: "100%",
              marginLeft: isMobile ? "-5px" : "",
            }}
          >
            <SupportModal/>
            <Logoutmsal />
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Sidebar;


