import React, { useContext, useState, useEffect } from "react";
import {
  Navbar as BootstrapNavbar,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { SidebarContext } from "../../contexts/SidebarContext";
import { useUser } from "../../contexts/UserContext";
import UserAvatar from "../../Helper/UserAvatar";
import { useMsal } from "@azure/msal-react";
import { handleLogout } from "../../pages/LoginPage/Logoutmsal";
import { BoxArrowInRight, Person } from "react-bootstrap-icons";
import { Mail } from "lucide-react";
 
const CustomNavbar = () => {
  const { isOpen } = useContext(SidebarContext);
  const { user } = useUser();
  const { instance } = useMsal();
  const [showModal, setShowModal] = useState(false);
  const [isProfilePictureValid, setIsProfilePictureValid] = useState(false);
 
  useEffect(() => {
    if (user?.profilePicture) {
      const img = new Image();
      img.src = user.profilePicture;
      img.onload = () => setIsProfilePictureValid(true);
      img.onerror = () => setIsProfilePictureValid(false);
    } else {
      setIsProfilePictureValid(false);
    }
  }, [user?.profilePicture]);
 
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
 
  const confirmLogout = () => {
    handleLogout(instance);
    handleCloseModal();
  };
 
  return (
    <div>
      <BootstrapNavbar
        fixed="top"
        style={{
          marginLeft: isOpen ? "250px" : "60px",
          width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
          height: "60px",
          transition: "width-left 0.3s",
          boxShadow: "none",
          backgroundColor: "#ffff",
          borderBottom: "2px solid #f4f4f4",
          zIndex: 1000,
        }}
      >
        <span
          className="font-semibold text-3xl ml-5"
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          Codintools
        </span>
 
        {user && (
          <Dropdown align="end" className="ml-auto">
            <Dropdown.Toggle
              variant="link"
              aria-label="user-avatar"
              className="p-0 border-0 bg-transparent flex items-center no-underline"
              bsPrefix="custom-dropdown-toggle"
              style={{
                marginLeft: "auto",
                marginRight: "15px",
                padding: "5px 15px",
                display: "flex",
                textDecoration: "none",
              }}
            >
              {isProfilePictureValid ? (
                <img
                  src={user.profilePicture}
                  alt="User Avatar"
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : user?.name ? (
                <UserAvatar name={user.name} />
              ) : null}
            </Dropdown.Toggle>
 
            <Dropdown.Menu className="py-3 px-4" style={{ minWidth: "280px" }}>
              <div className="flex flex-col items-start">
                <div className="flex items-center gap-3 w-full">
                  {isProfilePictureValid ? (
                    <img
                      src={user.profilePicture}
                      alt="User Avatar"
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full object-cover">
                      {user?.name ? <UserAvatar name={user.name} /> : null}
                    </div>
                  )}
                  <div className="flex flex-col">
                    <span className="font-inter font-semibold text-base text-gray-900">
                      {user.name}
                    </span>
                    <span className="font-inter text-sm text-gray-500">
                      {user.email}
                    </span>
                  </div>
                </div>
 
                <div className="w-full mt-3 pt-3 border-t border-gray-100">
                  <span
                    className="text-black font-inter"
                    style={{ fontWeight: 500 }}
                  >
                    Reports to
                  </span>
                  <div className="flex items-center space-x-2 text-gray-800">
                    <Person className="w-4 h-4 text-gray-500" />
                    <span>{user.managerName}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Mail className="text-gray-500" size={16} />
                    <span className="font-inter text-sm text-gray-500">
                      {user.managerEmail}
                    </span>
                  </div>
                </div>
 
                <div className="w-full mt-3 pt-3 border-t border-gray-100">
                  <Button
                    variant="link"
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      padding: "0",
                      border: "none",
                      background: "transparent",
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      fontFamily: "Inter, sans-serif",
                    }}
                    onClick={handleShowModal}
                  >
                    <BoxArrowInRight
                      style={{
                        marginRight: "8px",
                        fontSize: "18px",
                      }}
                    />
                    Logout
                  </Button>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </BootstrapNavbar>
 
      {showModal && (
        <div
          style={{
            position: "absolute",
            top: "60px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to logout from Codintools?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={confirmLogout}>
                Logout
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};
 
export default CustomNavbar;