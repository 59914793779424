import "react-toastify/dist/ReactToastify.css";
import { Container, Card } from "react-bootstrap";
import { Deal } from "../type/types";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import Graph from "../components/Graph/Graph";
import React, { useState, useEffect } from "react";
import Search from "../components/SearchFilter/SearchFilter";
import TableComponent from "../components/Table/TableComponent";
import Tab from "../components/Tab/Tab";

const itemsPerPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

const DealManagementsystem: React.FC = () => {
  const [queryField, setQueryField] = useState<string>("Select Field");
  const [searchString, setSearchString] = useState<string>("");
  const [data, setData] = useState<Deal[]>([]);
  const [filteredResults, setFilteredResults] = useState<Deal[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activeTab, setActiveTab] = useState("table");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (queryField !== "Select Field" && searchString.trim() !== "") {
      fetchResults(currentPage); 
    } else {
      fetchData(currentPage); 
    }
  }, [currentPage]);
  

  const fetchData = async (page: number) => {
    try {
      console.log(`Fetching data for page ${page}`);
      const response = await axios.get(
        `/api/getAllDeals?page=${page}&limit=${itemsPerPage}`
      );
      if (response.data.deals && Array.isArray(response.data.deals)) {
        setData(response.data.deals);
        setFilteredResults(response.data.deals);
        setTotalPages(response.data.totalPages);
      } else {
        console.error("Invalid data structure received:", response.data);
      }
    } catch (error) {
      console.error("Error fetching deals:", error);
    }
  };

  const handleQueryFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) =>{
    const value =  e.target.value;
    setQueryField(value);

    if(value === 'all'){
      setSearchString("");
      fetchData(1);
    }
}
  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchString(e.target.value);

  const validateSearchInput = (): boolean => {
    const validFields = [
      "dealNo",
      "owner",
      "dealStage",
      "dealstatus",
    ];

    if (queryField === "Select Field") {
      toast.warning("Please select a field.", { position: "top-right" });
      return false;
    }

    if (!validFields.includes(queryField)) {
      toast.warning("Please select a valid field.", { position: "top-right" });
      return false;
    }

    if (!searchString.trim()) {
      toast.warning(`Please enter a value for ${queryField}.`, { position: "top-right" });      
      return false;
    }

    if (
      queryField === "dealNo" &&
      !/^[A-Za-z0-9]+$/.test(searchString.trim())
    ) {
      toast.warning("Please enter correct DealNo.", { position: "top-right" });
      return false;
    }

    if (
      (queryField === "owner") &&
      !/^[A-Za-z\s]+$/.test(searchString.trim())
    ) {
      toast.warning(`Please Enter Correct ${queryField} Value.`, { position: "top-right" });
      return false;
    }

    if (
      queryField === "dealStage" &&
      !/^[A-Za-z0-9]+$/.test(searchString.trim())
    ) {
      toast.warning("Deal Stage must contain only number and letters.",{position:"top-right"});
      return false;
    }

    if (
      queryField === "dealstatus" &&
     !/^[A-Za-z\s-]+$/i.test(searchString.trim())
    ) {
      toast.warning("Invalid Deal Status.", { position: "top-right" });
      return false;
    }
    return true;
  };

  const fetchResults = async (page = 1) => {
    if(queryField === "all"){
      fetchData(page);
      return;
    }
        if (!validateSearchInput()) return;

    try {
      const response = await axios.post(`/api/query?page=${page}&limit=${itemsPerPage}`, {
        field: queryField,
        searchString,
      });
  
      if (response.data.deals && Array.isArray(response.data.deals) && response.data.deals.length > 0) {
        setFilteredResults(response.data.deals);
        setTotalPages(response.data.totalPages); 
        setCurrentPage(page); 
      } else {
        setFilteredResults([]);
        setTotalPages(1);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error fetching query results:", error);
      setFilteredResults([]);
      setTotalPages(1);
      setCurrentPage(1);
    }
  };


  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
  
      if (queryField !== "Select Filed" && searchString.trim() !== "") {
        fetchResults(page); 
      } else {
        fetchData(page);
      }
    }
  };

  const handleSearch = async () => {
    if (queryField === "") {
      toast.warning("Please select a field.", { position: "top-right" });
      return;
    }
  
    if (queryField === "all") {
      setLoading(true);
     await fetchData(1); 
     setLoading(false);
    } else {
      if (!validateSearchInput()) return;
      setLoading(true);
      setCurrentPage(1);
    await fetchResults(1);
    setLoading(false);
    }
  };
  

  const handleDealForm = () => {
    navigate("/dealform");
  };

  return (
    <div className="w-full p-4 min-h-screen mt-4">
      <ToastContainer />

      <div className="lg:ps-64 pt-8 px-12 md:px-6">
        <h2 className="text-2xl font-bold text-gray-800 whitespace-nowrap mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path d="M10 2h4a2 2 0 012 2v2h5a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2V8a2 2 0 012-2h5V4a2 2 0 012-2zm0 4V4h4v2h-4zM3 8v12h18V8H3z" />
          </svg>{" "}
          Deal Management
        </h2>
        <p className="text-gray-600 text-wrap mb-4">
          View and manage deals efficiently with detailed insights. You can
          analyze trends using graphs or work with structured data in a table
          format.{" "}
        </p>
      </div>

      <Container fluid className="flex-1 px-12 lg:ps-64 md:px-6">
        <Card
          className="shadow-lg p-3 border-2 border-gray-300 w-full"
          style={{ borderRadius: "15px", minHeight: "100%" }}
        >
          <Card.Body className="flex flex-col">
            <Tab activeTab={activeTab} setActiveTab={setActiveTab} />
            <h3 className="text-xl font-bold text-gray-700 mb-2">
              {activeTab === "table" ? "Deal Management Table" : ""}
            </h3>
            <p className="text-gray-600 mb-4">
              {activeTab === "table"
                ? "View and manage all deals in a structured table. Edit and filter deals for better organization."
                : ""}
            </p>
            {activeTab === "table" ? (
              <>
                <hr />
                <Search
                  queryField={queryField}
                  searchString={searchString}
                  handleQueryFieldChange={handleQueryFieldChange}
                  handleSearchStringChange={handleSearchStringChange}
                  fetchResults={handleSearch}
                  handleDealForm={handleDealForm}
                />
                <hr />
                <TableComponent
                  data={filteredResults}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              </>
            ) : (
              <Graph filteredResults={filteredResults} />
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default DealManagementsystem;
