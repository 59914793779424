import React from 'react';
import { CustomTooltipProps } from '../Types/Interface';

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const date = new Date(label as string);
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
    const isShortWorkDay = data.workingHours < 9;
    const shortage = isShortWorkDay ? 9 - data.workingHours : 0;
    const shortageHours = Math.floor(shortage);
    const shortageMinutes = Math.floor((shortage % 1) * 60);
    const shortageMessage = isShortWorkDay
      ? `Time Shortage: ${shortageHours}hr ${shortageMinutes}m`
      : "No time shortage";

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <p className="label">{`Date: ${label} (${dayOfWeek})`}</p>
        <p className="intro">{`Working Hours: ${data.formattedWorkingHours}`}</p>
        <p className="desc">{`Login Time: ${data.loginTime}`}</p>
        <p className="desc">{`Logout Time: ${
          data.isLoggedOut ? data.logoutTime : "Still logged in"
        }`}</p>
        <p className="desc">{shortageMessage}</p>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;