import React, { useContext, useMemo, useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { SidebarContext } from "../../contexts/SidebarContext";
import { useUser } from "../../contexts/UserContext";
import CardComponent from "../../components/Card/CardComponent";
import { getTodayData } from "../../utils/DateUtils";
import settings from "../../config/Config";
import { AnalyticsCard } from "../../components/Card/AnalyticsCard";
import WfhBarGraph from "../../graph/WfhBarGraph";
import EmployeeWorkHoursChart from "../../graph/EmployeeWorkHoursChart";
import { BarChart, Record } from "react-bootstrap-icons";
import axios from "axios";
import { CardComponentProps } from "../../Types";
import Banner from "../../components/Card/Banner";
import { TodayData } from "../../Types";

const Dashboard: React.FC = () => {
  const { isOpen } = useContext(SidebarContext);
  const { user } = useUser();
  const [workTracker, setWorkTracker] = useState<any[]>([]);
  const [workError, setWorkError] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  const [WorkData, setWorkData] = useState<TodayData | null>(null);

  useEffect(() => {
    const fetchWorkTracker = async () => {
      try {
        const response = await axios.get(
          `${settings.config.API_URL}employee-work-tracker/api`
        );
        setWorkTracker(response.data);
        setWorkError(null);
      } catch (error) {
        setWorkError("Error fetching work tracker data");
        setWorkTracker([]);
      }
    };

    fetchWorkTracker();
  }, []);


  const todayData = useMemo(() => {
    if (!user || workTracker.length === 0) return getTodayData(null);

    const userRecords = workTracker.find(
      (record) => record.employeeEmail === user.email
    );
    return getTodayData(userRecords);
  }, [workTracker, user]);

  const cardData: CardComponentProps[] = AnalyticsCard(todayData);
  return (
    <>
    <Container
      fluid
      style={{
        marginTop: "50px",
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: "#F9FAFB",
        paddingTop: "20px",
        paddingLeft: isOpen ? "275px" : "80px",
        transition: "padding-left 0.3s ease",
      }}
    >
      <div className="mb-1 align-items-center gap-2">
        <div>
          <span
            className="text-start font-semibold p-1 text-2xl"
            style={{ fontFamily: "Inter, sans-serif" }}
          >
            Productivity Info
          </span>
        </div>
        <div className="mt-3">
          {user?.record && !notification ? (
            <Banner
              variant="danger"
              message="You didn't checkout yesterday. Don’t miss out, complete your checkout now!"
              buttonLabel="Checkout Now"
              buttonVariant="danger"
              records={user.record}
            />
          ) : null}
        </div>
      </div>
      <Row
        className="align-items-center justify-content-center"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gap: "15px",
        }}
      >
        {workError ? (
          <Col className="text-center">
            <p style={{ color: "red" }}>{workError}</p>
          </Col>
        ) : (
          cardData.map((data, index) => (
            <Col key={index}>
              <CardComponent {...data} />
            </Col>
          ))
        )}
      </Row>
      <Row className="mb-3 my-4">
        <Col xs="auto" className="d-flex align-items-center">
          <BarChart size={25} style={{ marginRight: "8px", strokeWidth: 2 }} />
          <span className="fw-semibold fs-4">Analytics</span>
        </Col>
      </Row>

      <Container
        fluid
        style={{
          border: "2px solid #dee2e6",
          borderRadius: "8px",
          padding: "20px",
          minHeight: "400px",
          backgroundColor: "#ffff",
          marginTop: "-8px",
        }}
      >
        <h2 className="fs-5 fs-md-4 fw-semibold text-gray-700 text-center text-md-start">
          Work From Home Analysis
        </h2>

        <Card
          className="p-3 mt-2"
          style={{
            border: "none",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <WfhBarGraph />
        </Card>

        <h2 className="fs-5 fs-md-4 fw-semibold text-gray-700 mt-5 text-center text-md-start">
          Working Hours Distribution
        </h2>

        <Card
          className="p-3 mt-2"
          style={{
            border: "none",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <EmployeeWorkHoursChart />
        </Card>
      </Container>
    </Container>
    </>
  );
};

export default Dashboard;
