import { Button } from "react-bootstrap";
import React from "react";

interface PaginateButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
}

const PaginateButton: React.FC<PaginateButtonProps> = ({ onClick, icon, disabled = false }) => {
  return (
    <Button
      onClick={onClick}
      className="px-3 border rounded-md text-black"
      style={{ backgroundColor: "lightgray" }}
      disabled={disabled}
    >
      {icon}
    </Button>
  );
};

export default PaginateButton;
