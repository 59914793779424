import React from "react";
import { Container, Button, Card } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import logo from '../../assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
 
const LoginPage = () => {
  const { instance } = useMsal();
 
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error("Login failed", e);
    });
  };
 
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
    <Card
      className="text-center"
      style={{
        width: "30rem",
        height: "300px",
        borderRadius: "20px",
        fontFamily: "'Inter', serif",
        fontWeight:600,
        backgroundColor: "white",
      }}
    >
      <Card.Img
        src={logo}
        alt="Codincity Logo"
        style={{ width: "60%", margin: "0 auto", paddingTop: "20px" }}
      />
      <Card.Body className="d-flex flex-column justify-content-between px-4 py-4">
        <div>
          <Card.Title
            style={{ fontSize: "1.2rem", marginBottom: "12px" }}
          >
            Welcome..!
          </Card.Title>
          <Card.Text className="mb-3" style={{marginTop: "20px"}}>
            Please sign in to access your portal.
          </Card.Text>
        </div>
        <Button variant="primary" style={{ width: "40%", alignSelf: "center", marginTop: "0px"}}  onClick={handleLogin}>
 
          Sign In
        </Button>
      </Card.Body>
    </Card>
  </Container>
  );
};
 
export default LoginPage;